













































































































































































import CompaniesService from "@/api/companies/companiesService";
import Vue from "vue";

export interface DocumentData {
  description: string;
  comments: string | null;
  id?: string;
  name: string;
  mandatory: boolean;
  multi_file: boolean;
}

export default Vue.extend({
  name: "KYBExtraDocumentForm",
  props: {
    extraDocumentProp: { required: true, type: Object as () => DocumentData },
  },
  data() {
    return {
      extraDocumentNameOptions: [] as DocumentData[],
      extraDocumentNameValue: "",
      loading: false,
      addNewDocumentType: false,
      readable: false,
      create: false,
      extraDocument: {
        description: "",
        comments: "",
        name: "",
        mandatory: false,
        multi_file: false,
      } as DocumentData,
    };
  },
  computed: {
    filteredFileTypesOptions(): Array<DocumentData> {
      return this.extraDocumentNameOptions.map((option) => ({
        ...option,
        text: this.$t(option.name),
        value: option.name,
      }));
    },
    getExtraDocumentOptionsDescription(): string {
      const matchingOption = this.extraDocumentNameOptions.find(
        (option) => option.name === this.extraDocument.name
      );
      return matchingOption?.description || "-";
    },
    defaultOption(): boolean {
      return this.extraDocumentNameOptions.some(
        (option) => option.name === this.extraDocument.name
      );
    },
  },
  watch: {
    extraDocumentProp: {
      handler(newVal) {
        if (newVal?.name) {
          this.extraDocument = { ...newVal };
          this.readable = true;
        } else {
          this.create = true;
        }
      },
      immediate: true,
    },
    extraDocumentNameValue(newValue) {
      this.extraDocument.name = newValue;
    },
    readable(newVal) {
      this.addNewDocumentType = false;
      if (!newVal) {
        if (!this.defaultOption) {
          this.addNewDocumentType = true;
          return;
        }
        this.extraDocumentNameValue = this.extraDocument.name;
      }
    },
    addNewDocumentType() {
      if (this.addNewDocumentType && this.defaultOption) {
        this.extraDocument.name = "";
        this.extraDocument.description = "";
      }
    },
  },
  methods: {
    saveChanges() {
      const matchingOption = this.extraDocumentNameOptions.find(
        (option) => option.name === this.extraDocument.name
      );
      if (matchingOption) {
        this.extraDocument.id = matchingOption.id;
      }
      this.extraDocument.description =
        this.extraDocument.description ||
        this.getExtraDocumentOptionsDescription;

      this.$emit("onUpdateExtraDocumentData", this.extraDocument);
      this.readable = true;
    },
    handleCancelButtonClick() {
      if (this.readable) {
        this.$emit("deleteExtraDocumentForm", this.extraDocument.id);
      } else if (this.create) {
        this.$emit("cancelExtraDocumentForm");
      } else {
        this.readable = true;
        this.extraDocument = { ...this.extraDocumentProp };
      }
    },
    async fetchFileTypes() {
      this.loading = true;
      try {
        const res = await CompaniesService.getFileTypes("KYB");
        this.extraDocumentNameOptions = res.data.results;
        if (!this.extraDocumentProp.name) {
          this.extraDocumentNameValue =
            this.extraDocumentNameOptions[0]?.name || "";
        }
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    if (!this.extraDocumentNameOptions.length) await this.fetchFileTypes();
  },
});
