












































































































type VerificationAPITypeStatusHash = {
  [vtype in VerificationAPIType]: VerificationStatus;
};
import { Component, Vue, Watch } from "vue-property-decorator";
import UserModal from "@/components/users/UserModal.vue";
import UsersService from "@/api/users/usersService";
import { VTableHeader } from "@/components/vuemarc-ui-kit/tables/VTable.vue";
import ResourcesService from "@/api/resources/resourcesService";
import { PATypeStatus } from "@/lib/parsers";
import {
  USER_STATUSES,
  VerificationStatus,
  VERIFICATION_STATUS_MAP,
} from "@/models/verificationStatus";
import {
  VerificationAPIType,
  VerificationsMap,
  VERIFICATIONS_TYPES_MAP,
} from "@/models/verificationsTypes";

@Component({
  components: {
    UserModal,
  },
})
export default class Users extends Vue {
  headers: VTableHeader[] = [
    {
      name: "status",
      text: "status_title",
      class: "shrinked",
      style: "min-width: 180px",
    },
    {
      name: "resources",
      text: "users_resources_title",
      class: "shrinked",
      style: "min-width: 180px",
    },
    {
      name: "checks",
      text: "users_checks_title",
      class: "shrinked",
      style: "min-width: 180px",
    },
    { name: "name", text: "users_name_title", class: "capitalized" },
    {
      name: "created_at",
      text: "created_date_title",
      class: "text-right shrinked",
    },
  ];

  statusOptions = [
    { value: "SUCCESS" },
    { value: "ERROR" },
    { value: "PENDING" },
  ];

  userDetail: any = null;
  resourceDetail: any = null;
  showResourceDetail = false;
  loadingUser = false;
  VerificationsMap = VerificationsMap;
  VERIFICATIONS_TYPES_MAP = VERIFICATIONS_TYPES_MAP;
  companyAppSelectedPermissions: any[] = [];
  //Edge case control: User has rule on error and company has no rule requirement anymore => User Status: Error, Check Status: Error
  checksRequirements: any[] = [];
  resourcesRequirements: any[] = [];
  users: any[] = [];
  searchParamsFromHeaderPagination = new URLSearchParams();
  filterOptions = USER_STATUSES;

  get isKYCFlow() {
    return this.$store.state.verificationFlow.flow_type === "UDV";
  }

  @Watch("showResourceDetail")
  closeUserModal() {
    if (!this.showResourceDetail) this.$router.push({ name: "users" });
  }

  async loadMoreUsers() {
    this.loadingUser = true;
    await (this.$refs.table as any).getNext();
    this.loadingUser = false;
  }

  async getUsers(params: URLSearchParams) {
    if (!this.isKYCFlow) return;
    const users = await UsersService.getUsersOfCompany(params);
    if (!this.companyAppSelectedPermissions.length)
      this.getCompanyAppSelectedPermissions(this.$store.state.companyAppId);

    if (!this.checksRequirements.length || !this.resourcesRequirements.length)
      this.getFilteredPermissions(this.companyAppSelectedPermissions);
    return users;
  }

  async getUser(userid: string) {
    const user = await UsersService.getUserStatus(userid);
    return user.data;
  }

  getUserRequirementsPAs(userProcessingAttempts: Array<any>) {
    if (!userProcessingAttempts?.length) return [];

    const excludedTypes = new Set(this.checksRequirements || []);
    const AUXResourcesRequirements = new Set(this.resourcesRequirements || []);

    for (const { permission_type } of userProcessingAttempts) {
      if (
        !AUXResourcesRequirements.has(permission_type) &&
        !excludedTypes.has(permission_type)
      ) {
        AUXResourcesRequirements.add(permission_type);
      }
    }

    const result = Array.from(AUXResourcesRequirements);
    return result;
  }

  selectPAPerStatusPriority(processingAttempts: any[]) {
    if (!processingAttempts || processingAttempts.length === 0) return;

    let processingAttempt = processingAttempts.find(
      (processingAttempt: any) =>
        processingAttempt.status ===
          VERIFICATION_STATUS_MAP.VERIFICATION_ERROR ||
        processingAttempt.status === VERIFICATION_STATUS_MAP.ERROR
    );
    if (processingAttempt) return processingAttempt;

    processingAttempt = processingAttempts.find(
      (processingAttempt: any) =>
        processingAttempt.status === VERIFICATION_STATUS_MAP.MANUAL_REVIEW
    );
    if (processingAttempt) return processingAttempt;

    for (const resourceRequirement of this.resourcesRequirements) {
      const processingAttempt = processingAttempts.find(
        (processingAttempt: any) =>
          resourceRequirement === processingAttempt.permission_type
      );
      if (processingAttempt) return processingAttempt;
    }

    return processingAttempts[0];
  }

  getUserPrefilled(userId: any) {
    this.userDetail =
      this.$refs.table &&
      (this.$refs.table as any).rowsData.results.find((user: any) => {
        if (user.public_id) {
          return user.public_id === userId;
        } else {
          return user.id === userId;
        }
      });
    if (this.userDetail && this.userDetail.processing_attempts)
      this.resourceDetail = this.selectPAPerStatusPriority(
        this.userDetail.processing_attempts
      );
  }

  async showAndGetResourceDetail(user: any) {
    this.showResourceDetail = true;

    this.loadingUser = true;
    this.getUserPrefilled(user.public_id ? user.public_id : user.id);
    this.userDetail = await this.getUser(
      user.public_id ? user.public_id : user.id
    );
    this.resourceDetail = this.selectPAPerStatusPriority(
      this.userDetail.processing_attempts
    );
    this.loadingUser = false;

    const userid = user.public_id || user.id;
    if (userid && userid != this.$route.params.userid) {
      this.$router.push({ name: "users-detail", params: { userid } });
    }
  }

  getCompanyAppSelectedPermissions(companyAppId: string) {
    if (!this.$store.state.company) return;
    for (const companyApp of this.$store.state.company.company_apps) {
      if (companyApp.app_id === companyAppId) {
        this.companyAppSelectedPermissions = companyApp.permissions;
      }
    }
  }

  getFilteredPermissions(companyAppPermissions: any) {
    const substringCondition = ":background";
    this.checksRequirements = [];
    this.resourcesRequirements = [];
    for (const permissionGroup of companyAppPermissions) {
      if (permissionGroup && permissionGroup.length) {
        for (const permission of permissionGroup) {
          if (permission && permission.includes(substringCondition)) {
            this.checksRequirements.push(
              permission.substring(0, permission.indexOf(":"))
            );
          } else {
            this.resourcesRequirements.push(permission);
          }
        }
      }
    }
  }

  getPATypeStatusPerUser(user: any): VerificationAPITypeStatusHash {
    if (user.statusRequirements) return user.statusRequirements;
    return (user.statusRequirements = PATypeStatus(user.processing_attempts)); // {"NATIONAL_ID": "SUCCESS"}
  }

  getStatusPerRequirementGroup(
    requirements: Array<VerificationAPIType>,
    patypeStatus: VerificationAPITypeStatusHash
  ): VerificationStatus {
    let returnStatus: VerificationStatus | null = "SUCCESS";
    for (const requirement of requirements) {
      if (patypeStatus[requirement] === VERIFICATION_STATUS_MAP.SUCCESS) {
        continue;
      } else if (
        patypeStatus[requirement] === VERIFICATION_STATUS_MAP.MANUAL_REVIEW ||
        patypeStatus[requirement] === VERIFICATION_STATUS_MAP.PENDING
      ) {
        returnStatus =
          VERIFICATION_STATUS_MAP.MANUAL_REVIEW as VerificationStatus;
      } else if (
        patypeStatus[requirement] === VERIFICATION_STATUS_MAP.ERROR ||
        patypeStatus[requirement] === VERIFICATION_STATUS_MAP.VERIFICATION_ERROR
      ) {
        if (returnStatus !== VERIFICATION_STATUS_MAP.MANUAL_REVIEW) {
          returnStatus = VERIFICATION_STATUS_MAP.ERROR as VerificationStatus;
        }
      } else if (
        patypeStatus[requirement] === VERIFICATION_STATUS_MAP.BLOCKED
      ) {
        if (
          returnStatus !== VERIFICATION_STATUS_MAP.MANUAL_REVIEW &&
          returnStatus !== VERIFICATION_STATUS_MAP.ERROR
        ) {
          returnStatus = patypeStatus[requirement];
        }
      }
    }
    return returnStatus;
  }

  async getUserStatus(options?: { force: boolean; user_id?: string }) {
    if (!(options && options.force) && this.userDetail && this.resourceDetail)
      return;
    if (this.userDetail)
      this.userDetail.aml_info =
        this.userDetail.aml_info_updated_at =
        this.userDetail.pep_info =
        this.userDetail.pep_info_updated_at =
          null;
    else {
      this.userDetail = this.resourceDetail.owner_user;
      this.userDetail.aml_info =
        this.userDetail.aml_info_updated_at =
        this.userDetail.pep_info =
        this.userDetail.pep_info_updated_at =
          null;
    }
    this.loadingUser = true;
    const userId = (options && options.user_id) || this.userDetail.id;
    const resUser = await UsersService.getUserStatus(userId);
    if (!resUser.data) return;
    this.userDetail = resUser.data;
    this.loadingUser = false;
  }

  async updateResource(resource: any) {
    const res = await ResourcesService.patchResourceStatus(
      resource.processing_attempt_id,
      resource.status
    );
    await this.getUserStatus({
      force: true,
      user_id: resource.owner_user && resource.owner_user.id,
    });
    resource.status = res.data.status;
    resource.manual_review_status = res.data.manual_review_status;
    return res;
  }

  async mounted() {
    if (this.$route.params.userid) {
      // TODO avoid duplicated call getuserstatus in user.vue and padetail.vue
      let user = await this.getUser(this.$route.params.userid);
      this.showAndGetResourceDetail(user);
    }
  }
}
