






























































































































import MisconductListService from "@/api/misconductList/misconductListService";
import OnelineVerifications from "@/components/OnelineVerifications.vue";
import Vue from "vue";
import PADetailAmlPepSumup from "@/components/processing-attempts/pa-detail/components/PADetailAmlPepSumup.vue";
import MisconductModalAdd from "@/components/misconduct-list/MisconductModalAdd.vue";
import KYBModalDetailHeader from "./KYBModalDetailHeader.vue";
import KYBTempTokenButton from "./components/KYBTempTokenButton.vue";

export default Vue.extend({
  name: "KYBUserStatusInfo",
  components: {
    OnelineVerifications,
    PADetailAmlPepSumup,
    MisconductModalAdd,
    KYBModalDetailHeader,
    KYBTempTokenButton,
  },
  props: {
    kybUserDetail: {
      required: true,
      type: Object,
    },
    showStatus: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      misconductOptions: [] as any,
      showMisconductDetail: false,
      loading: false,
    };
  },
  methods: {
    onOpenMisconductModal() {
      this.showMisconductDetail = true;
      this.getOptionsOfMisconductList();
      this.$root.$emit("analytics", "Users - Add to Misconduct List Clicked");
    },
    getOptionsOfMisconductListValue(misconductOptions: any) {
      const newMisconductOptions = [];
      for (const option of misconductOptions) {
        option.value = option.name;
        option.text = this.$t(option.name);
        newMisconductOptions.push(option);
      }
      this.misconductOptions = newMisconductOptions;
      return misconductOptions;
    },
    async getOptionsOfMisconductList() {
      this.loading = true;
      const res = await MisconductListService.getMisconductTypes();
      this.loading = false;

      this.getOptionsOfMisconductListValue(res.data);
    },
    downloadCertificate(url: string) {
      window.open(url, "_blank");
    },
  },
});
