










































































































































































































import KYBService from "@/api/kyb/kybService";
import ResourcesService from "@/api/resources/resourcesService";
import KYBModalDetailHeader from "@/components/kyb/components/KYBModalDetailHeader.vue";
import KYBUBOCompanyDetail from "@/components/kyb/components/KYBUBOCompanyDetail.vue";
import UBOBasicInfoDetail from "@/components/kyb/components/UBOBasicInfoDetail.vue";
import UBODiagram from "@/components/kyb/components/UBODiagram.vue";
import KYBCompanyInfo from "@/components/kyb/KYBCompanyInfo.vue";
import KYBExtraDocuments from "@/components/kyb/KYBExtraDocuments.vue";
import KYBSanctionsChecks from "@/components/kyb/KYBSanctionsChecks.vue";
import KYBStatusInfo from "@/components/kyb/KYBStatusInfo.vue";
import UserDetail from "@/components/users/UserDetail.vue";
import Vue from "vue";
import KYBProgressTabs from "./KYBProgressTabs.vue";
import KYBCPOADetail from "@/components/kyb/KYBCPOADetail.vue";
import { getCountriesList } from "@/lib/utils";
import KYBAdverseMediaChecks from "@/components/kyb/KYBAdverseMediaChecks.vue";
import KYBRepresentativeDetail from "@/components/kyb/KYBRepresentativeDetail.vue";
import KYBUserStatusInfo from "@/components/kyb/components/KYBUserStatusInfo.vue";
// TODO: Refactor - Rename the 'Admin' properies to 'Representative'
export default Vue.extend({
  components: {
    KYBCompanyInfo,
    KYBStatusInfo,
    UBODiagram,
    UBOBasicInfoDetail,
    UserDetail,
    KYBUBOCompanyDetail,
    KYBModalDetailHeader,
    KYBExtraDocuments,
    KYBProgressTabs,
    KYBSanctionsChecks,
    KYBCPOADetail,
    KYBAdverseMediaChecks,
    KYBRepresentativeDetail,
    KYBUserStatusInfo,
  },
  name: "KYBModal",
  props: {
    showKYBModal: { required: true, type: Boolean },
    KYBCompanyDetail: { required: true, type: Object },
    loadingProp: { required: true, type: Boolean },
    KYBCompanyDetailSanctions: { required: true, type: Object },
    KYBCompanyDetailAdverseMedia: { required: true, type: Object },
    KYBCompanyReminders: { required: true, type: Array },
    loadingReminders: { required: true, type: Boolean },
    getSanctionsAndAdverseMedia: { required: true, type: Function },
  },
  data() {
    return {
      activeTabName: "companyInfo",
      showUBODetail: false,
      uboDetail: null as any,
      representativeDetailKYBCompanyRoot: null as any,
      loading: false,
      KYBUBOCompanySelected: null as any,
      countriesList: [] as Array<any>,
      KYBCompanyDetailAdverseMediaUBO: {} as any,
      KYBCompanyDetailSanctionsUBO: {} as any,
    };
  },
  computed: {
    companyStatusClass() {
      const kybStatus = this.KYBCompanyDetail.status;

      return {
        "fa fa-check-circle":
          kybStatus === "SUCCESS" || kybStatus === "MANUAL_SUCCESS",
        "fa fa-times-circle":
          kybStatus === "MANUAL_ERROR" || kybStatus === "ERROR",
        "fa fa-question-circle":
          kybStatus === "PENDING" ||
          kybStatus === "MANUAL_REVIEW" ||
          kybStatus === "SCREENING",
        [kybStatus === "PENDING" ? "IN_PROGRESS" : `status_${kybStatus}`]: true,
      };
    },
    KYBCompanyDetailPrincipals() {
      return (
        this.KYBCompanyDetail.details &&
        this.KYBCompanyDetail.details.principals
      );
    },
    CPOAProcessingAttempts() {
      return (
        this.KYBCompanyDetail?.processing_attempts?.filter(
          (attempt: any) => attempt.permission_type === "CPOA"
        ) || []
      );
    },
    KYBCompanyDetailRepresentativeInformation() {
      return (
        this.KYBCompanyDetail.details &&
        this.KYBCompanyDetail.details.representative_information
      );
    },
  },
  methods: {
    onCloseKYBModal() {
      this.$emit("showKYBModal", false);
      this.uboDetail = null;
      this.representativeDetailKYBCompanyRoot = null;
      this.KYBUBOCompanySelected = null;
      this.activeTabName = "companyInfo";
      this.$router.push({ name: "companies" });
    },

    async getKYBIndividual(userId: string) {
      const user = await KYBService.getKYBIndividual(userId);

      return user.data;
    },

    clearAmlAndPepInfo() {
      if (this.uboDetail) {
        this.uboDetail.aml_info = null;
        this.uboDetail.aml_info_updated_at = null;
        this.uboDetail.pep_info = null;
        this.uboDetail.pep_info_updated_at = null;
      }
    },

    async getKYBIndividualStatus(options?: { force: boolean }) {
      if (!(options && options.force) && this.uboDetail) return;
      this.clearAmlAndPepInfo();

      this.loading = true;

      try {
        const resUser = await this.getKYBIndividual(this.uboDetail.id);
        if (resUser) {
          this.uboDetail = resUser;
          this.uboDetail.processing_attempts = this.uboDetail.verifications;
        }
      } finally {
        this.loading = false;
      }
    },

    async updateResource(resource: any) {
      this.loading = true;
      const res = await ResourcesService.patchResourceStatus(
        resource.processing_attempt_id,
        resource.status
      );
      if (
        this.uboDetail.type &&
        (this.uboDetail.type.includes("ADMIN") ||
          this.uboDetail.type.includes("UBO")) &&
        !this.uboDetail.ubo_list
      )
        await this.getKYBIndividualStatus({ force: true });
      else {
        const res = await KYBService.getKYBCompany(this.uboDetail.id);
        this.uboDetail = res.data;
      }
      resource.status = res.data.status;
      resource.manual_review_status = res.data.manual_review_status;
      this.$emit("getKYBCompanyDetail", this.KYBCompanyDetail.id);
      this.loading = false;
      return res;
    },

    async showAndGetUBODetail(event?: any) {
      this.uboDetail = event;

      if (!this.uboDetail) return;
      if (
        this.KYBUBOCompanySelected === this.uboDetail.id ||
        (this.uboDetail.user &&
          this.KYBUBOCompanySelected === this.uboDetail.user.id)
      ) {
        this.KYBUBOCompanySelected = null;
      } else {
        this.KYBUBOCompanySelected = this.uboDetail.id;
      }
      if (this.uboDetail) {
        if (
          this.uboDetail.type &&
          (this.uboDetail.type.includes("ADMIN") ||
            this.uboDetail.type.includes("UBO")) &&
          !this.uboDetail.ubo_list
        ) {
          this.loading = true;

          this.uboDetail = await this.getKYBIndividual(this.uboDetail.id);
          this.uboDetail.processing_attempts = this.uboDetail.verifications;

          this.uboDetail.ignored = event.ignored;

          if (
            this.uboDetail.type.includes("ADMIN") &&
            !this.representativeDetailKYBCompanyRoot
          ) {
            this.representativeDetailKYBCompanyRoot = this.uboDetail;
          }

          this.loading = false;
        } else {
          this.loading = true;
          const res = await KYBService.getKYBCompany(this.uboDetail.id);
          const { sanctions, adverseMedia } =
            await this.getSanctionsAndAdverseMedia(this.uboDetail.id);
          if (adverseMedia) this.KYBCompanyDetailAdverseMediaUBO = adverseMedia;
          if (sanctions) this.KYBCompanyDetailSanctionsUBO = sanctions;

          this.uboDetail = res.data;
          this.uboDetail.is_company = true;

          this.loading = false;
        }
      }

      this.showUBODetail = true;
    },
  },
  async mounted() {
    this.countriesList = await getCountriesList({ iso: "iso2" });
  },
});
