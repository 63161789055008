







































































































import Vue from "vue";
import { isUboInAnyChild } from "@/lib/parsers";

export default Vue.extend({
  name: "UBODiagramNode",
  props: {
    uboData: { required: true, type: Object },
    isAdmin: { required: false, default: false },
    focusNode: { required: false, default: "", type: String },
    countriesList: { required: true, type: Array },
  },
  data() {
    return {
      isUboInAnyChild: isUboInAnyChild,
    };
  },
  computed: {
    fullName(): string {
      if (this.uboData && this.uboData.name) {
        return this.uboData.name.toLowerCase();
      } else if (
        this.uboData &&
        this.uboData.user &&
        this.uboData.user.first_name
      ) {
        let fullName = this.uboData.user.first_name;
        if (this.uboData.user.last_name) {
          fullName += " " + this.uboData.user.last_name;
        }
        return fullName.toLowerCase();
      } else {
        return "";
      }
    },
  },
  methods: {},
  mounted() {},
});
