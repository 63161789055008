







































































import VSwitch from "@/components/vuemarc-ui-kit/switch/VSwitch.vue";
import Vue from "vue";
import KYCDocumentTypes from "./KYCDocumentTypes.vue";
import VSelect from "@/components/vuemarc-ui-kit/inputs/VSelect.vue";

export default Vue.extend({
  components: { VSwitch, KYCDocumentTypes, VSelect },
  name: "KYCRequirements",
  props: {
    cappFilteredSyncVerifications: {
      required: true,
      type: Array as () => Array<any>,
    },
    companyAppPermissions: { required: true, type: Array as () => Array<any> },
    cappFilteredSyncVerificationsInput: {
      required: true,
      type: Array as () => Array<any>,
    },
    recurrentAMLOptions: { required: true, type: Array as () => Array<any> },
    recurrentAMLValue: { required: true, type: String },
  },
  watch: {
    AMLCheck() {
      if (!this.companyIsFreeTier)
        this.$emit("updateConfigurationFlow", {
          key: "AML:background",
          value: this.AMLCheck,
        });
    },
    PEPCheck() {
      if (!this.companyIsFreeTier)
        this.$emit("updateConfigurationFlow", {
          key: "PEP:background",
          value: this.PEPCheck,
        });
    },
  },
  data() {
    return {
      AMLCheck: false,
      PEPCheck: false,
      recurrentLocalAMLValue: this.recurrentAMLValue as string | undefined,
    };
  },
  computed: {
    companyIsFreeTier(): boolean {
      return (
        this.$store.state.company &&
        this.$store.state.company.plan_name === "free_tier"
      );
    },
  },
  methods: {
    getAMLAndPEPValues() {
      if (this.companyAppPermissions.length) {
        for (const permissionGroup of this.companyAppPermissions) {
          if (
            permissionGroup.some((item: string) =>
              item.includes("AML:background")
            )
          ) {
            this.AMLCheck = true;
          }
          if (
            permissionGroup.some((item: string) =>
              item.includes("PEP:background")
            )
          ) {
            this.PEPCheck = true;
          }
        }
      }
    },
  },
  mounted() {
    this.getAMLAndPEPValues();
  },
});
