



















































import RulesService from "@/api/rules/rulesService";
import VRuleOneLiner from "@/components/rules/VRuleOneLiner.vue";
import Vue from "vue";
import RuleHitsList from "./RuleHitsList.vue";
import { IRule } from "@/models/rules";

export default Vue.extend({
  components: { VRuleOneLiner, RuleHitsList },
  name: "RulesList",
  props: {
    settingsMode: { required: false, default: false },
    rulesList: { required: true, type: Array as () => Array<IRule> },
    companyAppId: { required: true, type: String },
  },
  data() {
    return {
      ruleClicked: "",
      activeRuleHit: {} as IRule,
      loading: false,
      users: [] as any,
    };
  },
  methods: {
    async getRuleHits() {
      const ruleHitsListUpdated = {
        results: [],
      };

      if (this.ruleClicked) {
        const res = await RulesService.getRuleHits(
          this.companyAppId,
          this.ruleClicked
        );

        this.activeRuleHit = res.data.rule;

        ruleHitsListUpdated.results = res.data.hits;
        this.users = res.data.hits;

        return ruleHitsListUpdated;
      }
    },

    showRuleHits(ruleId: string) {
      if (!this.settingsMode) {
        this.ruleClicked = ruleId;

        this.getRuleHits();

        this.$root.$emit("analytics", "Rules - View Rule Hits Clicked");
      }
    },

    goBackButton() {
      this.ruleClicked = "";

      if (this.$route.params.userId) this.$router.go(-1);
    },

    async enableOrDisableRule(rule: IRule) {
      if (rule.is_enabled)
        await RulesService.enableRule(this.companyAppId, rule.id);
      else await RulesService.disableRule(this.companyAppId, rule.id);
    },
  },
});
