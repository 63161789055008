














































































































































































import CDPService, {
  filterDeletedItemsAndReorderRows,
} from "@/api/cdp/cdpService";
import ProcessingAttemptsService from "@/api/processingAttempts/processingAttemptsService";
import CDPModal from "@/components/cdp/CDPModal.vue";
import {
  VTableHeader,
  VTableInstance,
} from "@/components/vuemarc-ui-kit/tables/VTable.vue";
import { truncateMiddleString } from "@/lib/parsers";
import { handleBeforeUnload } from "@/lib/utils";
import {
  CdpDefaultSkeleton,
  CDP_DEFAULT_SKELETON,
  LockedBy,
} from "@/models/cdp";
import { CDP_STATUSES } from "@/models/verificationStatus";
import Vue from "vue";
import CDPListBlockDocumentsModal from "./components/CDPListBlockDocumentsModal.vue";

const POLLING_DELAY_MULTIPLIER = 5;

export default Vue.extend({
  components: { CDPModal, CDPListBlockDocumentsModal },
  name: "CDPList",
  data() {
    return {
      loading: false,
      changelog: [] as Array<any>,
      truncateMiddleString: truncateMiddleString,
      searchByProcessingAttemptId: "",
      headers: [
        {
          name: "status",
          text: "cdp_table_stauts_processing",
          style: "max-width: 150px",
        },
        {
          name: "owner_user",
          text: "owner_user",
        },
        {
          name: "document_id",
          text: "cdp_table_document_id",
        },
        {
          name: "last_updated_by",
          text: "cdp_table_last_updated_by",
        },
        {
          name: "updated_at",
          text: "global_last_updated",
        },
        {
          name: "created_at",
          text: "cdp_table_created_date",
          sortable: true,
        },
        {
          name: "locked_by",
          text: "cdp_table_locked_by",
          class: "text-right shrinked",
          style: "min-width: 100px",
        },
        {
          name: "link",
          text: "link",
          class: "icon",
        },
      ] as VTableHeader[],
      newVersionAvailable: false,
      syncRowsData: [] as any,
      activeInvoiceDocument: {} as any,
      showInvoiceDocument: false,
      userDetail: {} as any,
      processingAttempt: {} as any,
      lastInvoiceDocumentFromDB: {} as CdpDefaultSkeleton,
      // resourceFiles: [] as Array<any>,
      documentsIds: [] as Array<string>,
      lastVersionDocumentProcessProps: {
        status: "",
        updated_at: "",
        created_at: "",
        raw_data: { confidence: 0 },
        reviewer_user: {},
      } as Partial<CdpDefaultSkeleton>,
      isLockedBy: null as LockedBy,
      intervalIds: {
        documentId: null as ReturnType<typeof setTimeout> | null,
        listId: null as ReturnType<typeof setTimeout> | null,
        blockedDocumentsListId: null as ReturnType<typeof setTimeout> | null,
      } as Record<string, ReturnType<typeof setTimeout> | null>,
      processingStatusOptions: {
        ERROR: "ERROR",
        RUNNING: "RUNNING",
        SUCCESS: "SUCCESS",
      },
      searchAdditionalParamsString: "",
      blockedListView: false,
      showBlockDocuments: false,
      blockedDocumentsLength: 0,
      loadingChangelog: false,
      detailPrefilledFromChangelog: false,
    };
  },
  watch: {
    "$store.state.verificationFlow.app_id": {
      handler() {
        this.resetAllPolling();
      },
    },
    searchParams: {
      handler() {
        this.resetAllPolling();
      },
    },
    searchAdditionalParamsString: {
      handler() {
        this.resetAllPolling();
      },
    },
    activeInvoiceDocument: {
      handler() {
        if (this.activeInvoiceDocument.id === "CDP_DEFAULT_SKELETON") return;
        if (this.showInvoiceDocument && this.isLastVersion) {
          this.stopPolling("documentId");
          this.startSyncPollingInvoiceDocument();
          this.fetchChangelog();
        } else this.stopPolling("documentId");
      },
      deep: true,
    },
    blockedListView() {
      this.updateSortableHeaders(this.headers);
    },
    processingAttempt: {
      deep: true,
      handler(oldValue, newValue) {
        if (oldValue.id !== newValue.id) {
          this.resetChangelog();
        }
      },
    },
    searchByProcessingAttemptId: {
      async handler() {
        if (this.searchByProcessingAttemptId) {
          this.$root.$emit("analytics", "CDP SearchBar Used");
          this.resetAllPolling();
          this.syncRowsData = [];
          this.updateLoadingCDPTableRef(true);
          await this.showAndGetCDPProcessingAttempt(
            this.searchByProcessingAttemptId,
            false
          );
          this.updateLoadingCDPTableRef(false);
        } else {
          this.getRowsFromCDPTableRef();
        }
      },
    },
    showInvoiceDocument() {
      if (this.showInvoiceDocument) {
        if (this.intervalIds.blockedDocumentsListId)
          this.stopPolling("blockedDocumentsListId");
        if (this.intervalIds.listId) this.stopPolling("listId");
      } else {
        this.startSyncDocumentsListPollingFromRefTable();
      }
    },
  },
  computed: {
    lockedByCurrentUser(): boolean {
      return this.isLockedBy?.id === this.$store.state?.user?.id;
    },
    isCDPFlow() {
      return this.$store.state.verificationFlow.flow_type === "CDP";
    },
    filterOptions() {
      const options: {
        APPROVED: string;
        PENDING?: string;
        MANUAL_REVIEW: string;
        ERROR: string;
        DISCARDED: string;
      } = { ...CDP_STATUSES };

      if (!["STAFF", "OWNER", "ADMIN"].includes(this.$store.state.userRole)) {
        delete options.PENDING;
      }

      return options;
    },
    activeCDP(): string {
      return this.activeInvoiceDocument?.id || "";
    },
    isLastVersion(): boolean {
      const hasChangelog = this.changelog && this.changelog.length > 0;

      if (!hasChangelog || this.changelog[0].id === "CDP_DEFAULT_SKELETON") {
        return false;
      }
      return this.changelog[0].id === this.activeCDP;
    },
  },
  methods: {
    onProcessingStatusFilterApplied(event: string) {
      this.searchAdditionalParamsString = event;
      this.$root.$emit("analytics", "CDP ProcessingStatusFilter Clicked");
    },
    showBlockDocumentsAndEmitAnalyticsEvent() {
      this.showBlockDocuments = true;
      this.$root.$emit(
        "analytics",
        "CDP BlockedByYou - BlockMoreDocuments Clicked"
      );
    },
    getCDPDocumentDetailFromChangelogAndSetActiveInvoiceDocument(documentInformation: {
      documentId: string;
      localInvoiceDocumentVersion: CdpDefaultSkeleton;
    }) {
      if (
        this.changelog.length > 0 &&
        this.changelog[0].id === documentInformation.documentId
      ) {
        this.setActiveInvoiceDocument(
          documentInformation.localInvoiceDocumentVersion
        );
        return;
      }
      this.getCDPDocumentDetailFromChangelog(documentInformation.documentId);
    },
    updateSortableHeaders(headers: Array<VTableHeader>) {
      for (const header of headers) {
        if ("sortable" in header) {
          header.sortable = !header.sortable;
        }
      }
    },
    setActiveInvoiceDocument(invoiceDocumentSelected: any) {
      this.activeInvoiceDocument = invoiceDocumentSelected;
      if (
        this.changelog.length === 0 &&
        this.activeInvoiceDocument &&
        this.activeInvoiceDocument.id &&
        this.activeInvoiceDocument.id !== "CDP_DEFAULT_SKELETON"
      )
        this.changelog.push(this.activeInvoiceDocument);
    },
    getDocumentProcessingStatusIcon(documentProcessingStatus: string) {
      if (!documentProcessingStatus) documentProcessingStatus = "BLOCKED";
      return {
        "fa fa-check-circle":
          documentProcessingStatus === "SUCCESS" ||
          documentProcessingStatus === "APPROVED",
        "fa fa-times-circle":
          documentProcessingStatus === "ERROR" ||
          documentProcessingStatus === "DISCARDED" ||
          documentProcessingStatus === "VERIFICATION_ERROR",
        "fa fa-question-circle":
          documentProcessingStatus === "PENDING" ||
          documentProcessingStatus === "MANUAL_REVIEW" ||
          documentProcessingStatus === "BLOCKED",
        [`${documentProcessingStatus}`]: true,
      };
    },
    openDetailNewWindow(id: string) {
      const url = `${process.env.VUE_APP_SILT_DASHBOARD_URL}/complex-document-processor/${id}?verification_flow_id=${this.$store.state.companyAppId}`;
      window.open(url, "_blank");
    },
    stopPolling(intervalName: string) {
      if (intervalName === "documentId") this.newVersionAvailable = false;
      const interval = this.intervalIds[intervalName];
      if (interval) {
        clearTimeout(interval);
        this.intervalIds[intervalName] = null;
      }
    },
    onCloseCDPModal() {
      this.showInvoiceDocument = false;
      this.newVersionAvailable = false;
      this.stopPolling("documentId");
      if (this.lockedByCurrentUser && this.$refs.CDPModal) {
        (this.$refs.CDPModal as any).unlockInvoiceDocument();
      }
      const currentRouteParams = this.$route.params;
      if (currentRouteParams && currentRouteParams.cdpId) {
        this.$router.push({ name: "complex-document-processor" });
      }
      this.resetData();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    },
    resetChangelog() {
      this.changelog = [];
      if (
        this.activeInvoiceDocument &&
        this.activeInvoiceDocument.id &&
        this.activeInvoiceDocument.id !== "CDP_DEFAULT_SKELETON"
      )
        this.changelog.push(this.activeInvoiceDocument);
    },
    resetData() {
      this.activeInvoiceDocument = {};
      this.changelog = [];
      this.processingAttempt = {};
      this.lastVersionDocumentProcessProps = {
        status: "",
        updated_at: "",
        created_at: "",
        raw_data: { confidence: 0 },
        reviewer_user: null,
      };
      this.userDetail = {};
    },
    async getCDPList(params?: URLSearchParams) {
      this.resetAllPolling();
      if (!this.isCDPFlow) return;
      const CDPsList = await CDPService.getCDPsList(
        this.$store.state.companyAppId,
        params,
        this.$store.state.userRole
      );
      return CDPsList;
    },
    async getProcessingAttempt(CDPId: string) {
      const processingAttempt =
        await ProcessingAttemptsService.getProcessingAttemptFromId(CDPId);
      return processingAttempt;
    },
    async showAndGetCDPProcessingAttempt(
      CDPId: string,
      showModalImmidatelly: boolean = true
    ) {
      this.$root.$emit("analytics", "CDP - Document Clicked");
      if (CDPId) {
        this.getCDPDocumentDetailPrefilled(CDPId);
        if (showModalImmidatelly)
          this.showInvoiceDocument = showModalImmidatelly;
        this.loading = true;
        try {
          const res = await this.getProcessingAttempt(CDPId);
          this.processingAttempt = res;
          this.userDetail = res.owner_user;
          // this.resourceFiles = await this.getFiles(this.processingAttempt.id);
          this.fetchChangelog(res.document_id);
          if (res.document_id) await this.getCDPDocumentDetail(res.document_id);
          else {
            this.activeInvoiceDocument = CDP_DEFAULT_SKELETON;
            this.activeInvoiceDocument.status = this.processingAttempt.status;
            this.activeInvoiceDocument.updated_at =
              this.processingAttempt.updated_at;
          }
          if (CDPId && CDPId !== this.$route.params.cdpId) {
            this.$router.push({
              name: "cdp-detail",
              params: { cdpId: CDPId },
            });
          }
          if (!showModalImmidatelly) {
            this.showInvoiceDocument = true;
            this.activeInvoiceDocument.processing_attempt_id =
              this.processingAttempt.id;
            this.syncRowsData.push(this.activeInvoiceDocument);
          }
        } finally {
          this.loading = false;
        }
      }
    },
    async getInvoiceDocument(CDPDocumentId: string) {
      const res = await CDPService.getCDPDetail(
        this.$store.state.verificationFlow.app_id,
        CDPDocumentId
      );
      return res;
    },
    setLastVersionDocumentProcessProps(documentData: CdpDefaultSkeleton) {
      this.lastVersionDocumentProcessProps = {
        status: documentData.status,
        updated_at: documentData.updated_at,
        created_at: this.processingAttempt.created_at,
        reviewer_user: documentData.reviewer_user,
        raw_data: { confidence: documentData.raw_data?.confidence },
      };
    },
    getCDPDocumentDetailPrefilled(processingAttemptId: string) {
      if (this.syncRowsData?.length) {
        const prefilledProcessingInfo = this.syncRowsData.find(
          (document: any) => {
            return document.processing_attempt_id === processingAttemptId;
          }
        );

        if (this.syncRowsData.length && prefilledProcessingInfo) {
          this.processingAttempt.id =
            prefilledProcessingInfo.processing_attempt_id;
          this.processingAttempt.created_at =
            prefilledProcessingInfo.created_at;
          this.processingAttempt.status =
            prefilledProcessingInfo.processing_status;
          this.userDetail = prefilledProcessingInfo.owner_user;
          this.setLastVersionDocumentProcessProps(prefilledProcessingInfo);
        }
      }
    },
    async getCDPDocumentDetail(CDPDocumentId: string) {
      this.loading = true;
      const res = (await this.getInvoiceDocument(
        CDPDocumentId
      )) as CdpDefaultSkeleton;
      this.setLastVersionDocumentProcessProps(res);
      this.lastInvoiceDocumentFromDB = res;
      this.setActiveInvoiceDocument(res);
      this.isLockedBy = res.locked_by;
      this.loading = false;
      this.newVersionAvailable = false;
      this.detailPrefilledFromChangelog = false;
    },
    async fetchChangelog(invoiceDocumentIdIn?: string) {
      const invoiceDocumentId =
        invoiceDocumentIdIn || this.activeInvoiceDocument.id;
      if (!invoiceDocumentId) return;
      this.loadingChangelog = true;
      const changelog = await CDPService.getChangelog(
        this.$store.state.verificationFlow.app_id,
        invoiceDocumentId
      );
      this.changelog = changelog;
      this.loadingChangelog = false;
    },
    getCDPDocumentDetailFromChangelog(CDPDocumentId: string) {
      const invoicedocumentSelected = this.changelog.find(
        (document) => document.id === CDPDocumentId
      );
      invoicedocumentSelected.data = filterDeletedItemsAndReorderRows(
        invoicedocumentSelected.data
      );
      this.setActiveInvoiceDocument(invoicedocumentSelected);
    },
    async updateInvoiceDocument(CDPDocumentUpdated: CdpDefaultSkeleton) {
      try {
        this.loading = true;
        this.stopPolling("documentId");
        const data = await CDPService.updateCDPDocument(
          this.$store.state.verificationFlow.app_id,
          CDPDocumentUpdated.id,
          CDPDocumentUpdated
        );
        await this.fetchChangelog();
        this.lastInvoiceDocumentFromDB = data;
        this.setActiveInvoiceDocument(data);
        this.getRowsFromCDPTableRef();
      } finally {
        this.loading = false;
      }
    },
    getRowsFromCDPTableRef() {
      if (!this.$refs) return;
      const cdpTableRef = this.$refs.CDPTable as VTableInstance;
      cdpTableRef.getRows();
    },
    updateLoadingCDPTableRef(loading: boolean) {
      if (!this.$refs) return;
      const cdpTableRef = this.$refs.CDPTable as VTableInstance;
      if (cdpTableRef) cdpTableRef.loading = loading;
    },

    async startSyncPollingInvoiceDocument() {
      const start = new Date().getTime();
      let difference = 1000;
      try {
        const syncDocument = (await this.getInvoiceDocument(
          this.activeInvoiceDocument.id
        )) as CdpDefaultSkeleton;
        if (
          this.showInvoiceDocument &&
          syncDocument &&
          this.activeInvoiceDocument &&
          this.activeInvoiceDocument.id === syncDocument.id &&
          syncDocument.updated_at !== this.activeInvoiceDocument.updated_at &&
          this.activeInvoiceDocument.locked_by === syncDocument.locked_by &&
          syncDocument.processing_status !==
            this.activeInvoiceDocument.processing_status
        ) {
          this.newVersionAvailable = true;
        }
        this.isLockedBy = syncDocument.locked_by;
      } finally {
        difference = new Date().getTime() - start;
        this.intervalIds.documentId = setTimeout(async () => {
          this.startSyncPollingInvoiceDocument();
        }, Math.max(difference, 1000) * POLLING_DELAY_MULTIPLIER);
      }
    },

    async getSyncDocumentIds() {
      if (this.documentsIds.length === 0) return [];
      const res = await CDPService.getCDPsSync(
        this.$store.state.verificationFlow.app_id,
        this.documentsIds
      );
      const syncDocumentsArray = Object.keys(res).map((key) => ({
        id: key,
        created_at: res[key].created_at,
        error: res[key].error,
        locked_by: res[key].locked_by,
        owner_user: res[key].owner_user,
        processing_attempt_id: res[key].processing_attempt_id,
        processing_status: res[key].processing_status,
        reviewer_user: res[key].reviewer_user,
        status: res[key].status,
        updated_at: res[key].updated_at,
      }));
      return syncDocumentsArray;
    },
    setDocumentsIds(results: Array<any>) {
      if (!results || results.length == 0) return;
      this.documentsIds = results.map((item: any) => item.id);
    },
    // TODO Refactor: Unify all polling handling functions into one
    async startSyncDocumentsListPolling(results: Array<any>) {
      if (this.intervalIds.listId) this.stopPolling("listId");
      if (this.showInvoiceDocument) return;
      const start = new Date().getTime();
      let difference = 1000;
      this.setDocumentsIds(results);
      try {
        this.syncRowsData = await this.getSyncDocumentIds();
      } finally {
        difference = new Date().getTime() - start;
        this.intervalIds.listId = window.setTimeout(
          this.startSyncDocumentsListPolling,
          Math.max(difference, 1000) * POLLING_DELAY_MULTIPLIER
        ) as any;
      }
    },
    async startSyncBlockedDocumentsListPolling() {
      if (this.intervalIds.blockedDocumentsListId)
        this.stopPolling("blockedDocumentsListId");
      const start = new Date().getTime();
      let difference = 1000;
      try {
        const syncRowsDataResponse =
          await CDPService.getCDPsListBlockedDocumentsByCurrentUser(
            this.$store.state.companyAppId,
            "0"
          );
        this.syncRowsData = syncRowsDataResponse;
      } finally {
        difference = new Date().getTime() - start;
        this.intervalIds.blockedDocumentsListId = window.setTimeout(
          this.startSyncBlockedDocumentsListPolling,
          Math.max(difference, 1000) * POLLING_DELAY_MULTIPLIER
        ) as any;
      }
    },
    startSyncDocumentsListPollingFromRefTable() {
      const refTable = this.$refs.CDPTable as VTableInstance;
      if (refTable && refTable.rowsData) {
        if (this.blockedListView) this.startSyncBlockedDocumentsListPolling();
        else this.startSyncDocumentsListPolling(refTable.rowsData?.results);
      }
    },
    initVisibilityChangeListener() {
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) this.stopPolling("listId");
        else {
          const refTable = this.$refs.CDPTable as VTableInstance;
          if (refTable && refTable.rowsData) {
            this.startSyncDocumentsListPollingFromRefTable();
          }
        }
      });
    },
    resetAllPolling() {
      this.documentsIds = [];
      this.stopPolling("listId");
      this.stopPolling("documentId");
      this.stopPolling("blockedDocumentsListId");
    },
    async toggleBlockByYouFilter() {
      this.blockedListView = !this.blockedListView;
      this.syncRowsData = [];
      if (this.blockedListView) {
        this.$root.$emit("analytics", "CDP BlockedByYou Clicked");
        await this.getCDPsListBlockedDocumentsByCurrentUser("0");
      } else this.getRowsFromCDPTableRef();
    },
    async getCDPsListBlockedDocumentsByCurrentUser(blockedDocuments: string) {
      this.$root.$emit(
        "analytics",
        "CDP BlockedByYou - Modal - BlockNDocuments Clicked"
      );
      this.resetAllPolling();
      this.updateLoadingCDPTableRef(true);
      this.syncRowsData =
        await CDPService.getCDPsListBlockedDocumentsByCurrentUser(
          this.$store.state.companyAppId,
          blockedDocuments
        );
      this.updateLoadingCDPTableRef(false);
      this.startSyncBlockedDocumentsListPolling();
      this.blockedDocumentsLength = this.syncRowsData.length;
    },
  },

  async mounted() {
    this.initVisibilityChangeListener();
    this.$root.$on(
      "cdpProcessingAttemptUploadedSuccessfully",
      this.getRowsFromCDPTableRef
    );
    if (this.$route.params.cdpId) {
      await this.showAndGetCDPProcessingAttempt(this.$route.params.cdpId);
    }
  },
  beforeDestroy() {
    this.resetAllPolling();
  },
});
