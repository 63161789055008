




































import Vue from "vue";
import VTab from "@/components/vuemarc-ui-kit/tabs/VTab.vue";
import RulesList from "@/components/rules/RulesList.vue";
import CreateRules from "@/components/rules/CreateRules.vue";
import RulesService from "@/api/rules/rulesService";
import { IRule } from "@/models/rules";

export default Vue.extend({
  name: "RulesPage",
  components: { VTab, RulesList, CreateRules },
  data() {
    return {
      createRule: false,
      loading: false,
      companyAppId: this.$store.state.companyAppId,
      rulesList: [] as Array<IRule>,
    };
  },
  methods: {
    async getRules() {
      this.loading = true;
      const res = await RulesService.getRules(this.companyAppId);

      this.rulesList = res.data;
      this.loading = false;
    },
  },
  async mounted() {
    this.getRules();
  },
});
