













































import Vue from "vue";
import { Check } from "../KYBRequirements/KYBVerificationRequirementsTabs.vue";

export default Vue.extend({
  name: "KYBRequirementStepTab",
  props: {
    title: { required: true, type: String },
    isRemovable: { required: false, type: Boolean, default: true },
    checks: { required: true, type: Array as () => Array<Check> },
    activeRequirementName: { required: true, type: String },
    checkIfIsSelected: { required: true, type: String },
  },
  data() {
    return {};
  },
  methods: {},
});
