










































































































































import Vue from "vue";
import { DocumentData } from "./KYBRequirements/KYBExtraDocumentForm.vue";
import {
  VERIFICATIONS_TYPES_VIEW_MAP,
  VerificationAPIType,
} from "@/models/verificationsTypes";
import { filteredSyncVerifications } from "@/lib/parsers";
import AppDetailVerificationFlowInfo from "../AppDetailVerificationFlowInfo.vue";
import AppDetailIntegrationInfo from "../AppDetailIntegrationInfo.vue";
import AppDetailStyle from "../AppDetailStyle.vue";
import KYBVerificationRequirements from "./KYBVerificationRequirements.vue";
import AppDetailEmailReminders from "../AppDetailEmailReminders.vue";
import AppDetailAdminsAndAccess from "../AppDetailAdminsAndAccess.vue";

export default Vue.extend({
  components: {
    AppDetailVerificationFlowInfo,
    AppDetailIntegrationInfo,
    AppDetailStyle,
    KYBVerificationRequirements,
    AppDetailEmailReminders,
    AppDetailAdminsAndAccess,
  },
  name: "AppDetailKYB",
  props: {
    verificationFlowProp: { required: false, type: Object },
    activeTabName: { required: false, type: String, default: "" },
    create: { required: false, type: Boolean, default: false },
    recurrentAMLOptions: { required: true, type: Array },
    loading: { required: true, type: Boolean },
    primaryColorHexFeedback: { required: true, type: String, default: "" },
    pushRequirement: { required: true, type: Function },
    verificationFlowType: { required: true, type: String },
  },
  data() {
    return {
      verificationFlow: this.verificationFlowProp || ({} as any),
      VERIFICATIONS_TYPES_VIEW_MAP: VERIFICATIONS_TYPES_VIEW_MAP,
      filteredSyncVerifications: filteredSyncVerifications,
      cappFilteredSyncVerifications: [[]] as Array<Array<VerificationAPIType>>,
      cappFilteredSyncVerificationsInput: [[]] as Array<
        Array<VerificationAPIType>
      >,
      logoFileUrl: null,
      extraDocuments:
        this.verificationFlowProp.extra_documents ||
        ([] as Array<DocumentData>),
      emptyExtraDocument: {
        name: "",
        description: "",
        mandatory: false,
        multi_file: false,
        comments: "",
      } as DocumentData,
      hasExtraDocuments: this.verificationFlowProp.extra_documents?.length > 0,
      adminHasAML: false,
      adminHasPEP: false,
      recurrentAdminAMLValue: "" as string | undefined,
      uboHasAML: false,
      uboHasPEP: false,
      recurrentUBOAMLValue: "" as string | undefined,
      cappFilteredSyncVerificationsAdmin: [[]] as Array<
        Array<VerificationAPIType>
      >,
      cappFilteredSyncVerificationsInputAdmin: [[]] as Array<
        Array<VerificationAPIType>
      >,
      cappFilteredSyncVerificationsUBO: [[]] as Array<
        Array<VerificationAPIType>
      >,
      cappFilteredSyncVerificationsInputUBO: [[]] as Array<
        Array<VerificationAPIType>
      >,
      uboRequirements: [] as any,
      adminRequirements: [] as any,
      emailReminders: null as null | string,
    };
  },
  watch: {
    extraDocuments: {
      handler() {
        this.updateExtraDocumentsAndEmit();
      },
      deep: true,
    },
    hasExtraDocuments() {
      this.updateExtraDocumentsAndEmit();
    },
    verificationFlowProp: {
      handler() {
        this.updateKYBVerificationFlow(this.verificationFlowProp);
      },
      immediate: true,
    },
    verificationFlow: {
      handler() {
        this.$emit("updateVerificationFlow", this.verificationFlow);
      },
      deep: true,
    },
  },
  methods: {
    updateExtraDocumentsAndEmit() {
      this.verificationFlow.extra_documents = this.hasExtraDocuments
        ? this.extraDocuments
        : [];
      this.$emit("updateVerificationFlow", this.verificationFlow);
    },
    updateKYBVerificationFlow(verificationFlowEvent: any) {
      this.verificationFlow = {
        ...this.verificationFlow,
        ...verificationFlowEvent,
        ...this.verificationFlow.style,
      };

      if (this.create) {
        this.verificationFlow.flow_type = "KYB";
        this.verificationFlow.company_id = this.$store.state.company.id;
      }

      this.verificationFlow = (({
        logo,
        has_accepted_conditions,
        api_token,
        app_id,
        company,
        created_at,
        updated_at,
        ...remainingProperties
      }) => remainingProperties)(this.verificationFlow);

      this.$emit("updateVerificationFlow", this.verificationFlow);
    },
    deleteExtraDocumentForm(extraDocumentId: string) {
      const updatedExtraDocuments = this.extraDocuments.filter(
        (document: DocumentData) => document.id !== extraDocumentId
      );

      this.extraDocuments = updatedExtraDocuments;
    },
    updateExtraDocument(extraDocumentReceived: DocumentData, index: number) {
      if (this.extraDocuments[index]) {
        this.extraDocuments[index] = extraDocumentReceived;
        this.updateExtraDocumentsAndEmit();
      }
    },
    calculateKYBRequirements() {
      this.adminRequirements = this.filteredSyncVerifications(
        this.cappFilteredSyncVerificationsInputAdmin
      );
      this.uboRequirements = this.filteredSyncVerifications(
        this.cappFilteredSyncVerificationsInputUBO
      );

      this.pushRequirement(this.adminRequirements, this.adminHasAML, [
        "AML:background" +
          (this.recurrentAdminAMLValue &&
          this.recurrentAdminAMLValue !== "Not Recurrent"
            ? `:${this.recurrentAdminAMLValue}`
            : ""),
      ]);

      this.pushRequirement(this.uboRequirements, this.uboHasAML, [
        "AML:background" +
          (this.recurrentUBOAMLValue &&
          this.recurrentUBOAMLValue !== "Not Recurrent"
            ? `:${this.recurrentUBOAMLValue}`
            : ""),
      ]);

      this.pushRequirement(this.adminRequirements, this.adminHasPEP, [
        "PEP:background",
      ]);
      this.pushRequirement(this.uboRequirements, this.uboHasPEP, [
        "PEP:background",
      ]);

      this.updateKYBVerificationFlow({
        config: {
          ...this.verificationFlow.config,
          admin_permissions: this.adminRequirements,
          ubo_permissions: this.uboRequirements,
        },
      });
    },
    updateEmailReminders(emailRemindersValue: string) {
      this.emailReminders = emailRemindersValue;
      this.updateKYBVerificationFlow({
        email_reminders_frequency: emailRemindersValue,
        email_reminders_enabled: !!emailRemindersValue,
      });
    },
    updateKYCAdminRequirementsConfig(verification?: any) {
      if (!verification)
        this.cappFilteredSyncVerificationsInputAdmin =
          this.filteredSyncVerifications(
            this.cappFilteredSyncVerificationsInputAdmin
          );
      else if (verification.key?.includes("AML"))
        this.adminHasAML = verification.value;
      else if (verification.key?.includes("PEP"))
        this.adminHasPEP = verification.value;

      this.cappFilteredSyncVerificationsAdmin = this.filteredSyncVerifications(
        this.cappFilteredSyncVerificationsInputAdmin
      );

      this.calculateKYBRequirements();
    },
    updateKYCUBORequirementsConfig(verification?: any) {
      if (!verification)
        this.cappFilteredSyncVerificationsInputUBO =
          this.filteredSyncVerifications(
            this.cappFilteredSyncVerificationsInputUBO
          );
      else if (verification.key?.includes("AML"))
        this.uboHasAML = verification.value;
      else if (verification.key?.includes("PEP"))
        this.uboHasPEP = verification.value;

      this.cappFilteredSyncVerificationsUBO = this.filteredSyncVerifications(
        this.cappFilteredSyncVerificationsInputUBO
      );

      this.calculateKYBRequirements();
    },
    setAMLAndPEPRecursiveness(
      permissions: Array<any>,
      target: any,
      key?: string
    ) {
      for (let permissionsGroup of permissions) {
        if (permissionsGroup.length) {
          const permissionsGroupSplit = permissionsGroup[0].split(":");

          const isAML = permissionsGroupSplit[0] === "AML";
          const isPEP = permissionsGroup.includes("PEP:background");

          if (isAML && permissionsGroupSplit[2]) {
            const foundOption: any = this.recurrentAMLOptions.find(
              (option: any) => permissionsGroupSplit[2] === option.value
            );
            if (foundOption) {
              if (isAML && key === "admin") {
                this.adminHasAML = true;
                target.recurrentAdminAMLValue = foundOption.value;
              }
              if (isAML && key === "ubo") {
                this.uboHasAML = true;
                target.recurrentUBOAMLValue = foundOption.value;
              }
            }
          } else if (isAML) {
            if (key === "admin")
              target.recurrentAdminAMLValue = "Not Recurrent";
            if (key === "ubo") target.recurrentUBOAMLValue = "Not Recurrent";
          }

          if (isPEP) {
            target.uboHasPEP = key === "ubo";
            target.adminHasPEP = key === "admin";
            target.hasPEP = true;
          }
        }
      }
    },
    setAMLAndPEPConditionsPermissions(kybCompanyAppPermissions: any) {
      this.adminHasAML = this.adminHasAML;
      this.adminHasPEP = this.adminHasPEP;
      this.uboHasAML = this.uboHasAML;
      this.uboHasPEP = this.uboHasPEP;
      this.recurrentAdminAMLValue = this.recurrentAdminAMLValue;
      this.recurrentUBOAMLValue = this.recurrentUBOAMLValue;
      this.setAMLAndPEPRecursiveness(
        kybCompanyAppPermissions.admin_permissions,
        this,
        "admin"
      );
      this.setAMLAndPEPRecursiveness(
        kybCompanyAppPermissions.ubo_permissions,
        this,
        "ubo"
      );
    },
    setKYCRequirements() {
      this.cappFilteredSyncVerificationsInputAdmin =
        this.filteredSyncVerifications(
          this.verificationFlowProp.config.admin_permissions
        );
      this.cappFilteredSyncVerificationsAdmin =
        this.cappFilteredSyncVerificationsInputAdmin;
      this.cappFilteredSyncVerificationsInputUBO =
        this.filteredSyncVerifications(
          this.verificationFlowProp.config.ubo_permissions
        );
      this.cappFilteredSyncVerificationsUBO =
        this.cappFilteredSyncVerificationsInputUBO;

      this.setAMLAndPEPConditionsPermissions(this.verificationFlowProp.config);
    },
  },
  mounted() {
    this.setKYCRequirements();
  },
});
