


















import KYBService from "@/api/kyb/kybService";
import Vue from "vue";

export default Vue.extend({
  name: "KYBTempTokenButton",
  props: {
    KYBCompanyId: { required: true, type: String },
    individualKYBUser: { required: true, type: Object },
  },
  data() {
    return {
      signup_url: process.env.VUE_APP_SILT_SIGNUP_URL,
      loading: false,
      copiedHash: {} as any,
    };
  },
  methods: {
    async generateKYBIndividualTempToken() {
      try {
        this.loading = true;
        const { data } = await KYBService.generateKYBIndividualTempToken(
          this.KYBCompanyId,
          this.individualKYBUser.id
        );
        this.generateAndCopyLink(data.token);
      } finally {
        this.loading = false;
      }
    },
    copy(text: string) {
      navigator.clipboard.writeText(text).then(() => {
        this.copiedHash[text] = true;
      });
    },
    generateAndCopyLink(tempToken: string) {
      const url = `${this.signup_url}/?temp_token=${tempToken}&company_app_id=${this.$store.state.verificationFlow.app_id}`;
      this.copy(url);
    },
  },
  mounted() {},
});
