import { render, staticRenderFns } from "./KYBRequirementsExtraDocuments.vue?vue&type=template&id=c927ee68&scoped=true&"
import script from "./KYBRequirementsExtraDocuments.vue?vue&type=script&lang=ts&"
export * from "./KYBRequirementsExtraDocuments.vue?vue&type=script&lang=ts&"
import style0 from "./KYBRequirementsExtraDocuments.vue?vue&type=style&index=0&id=c927ee68&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c927ee68",
  null
  
)

export default component.exports