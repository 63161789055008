import { render, staticRenderFns } from "./VerificationFlowSettings.vue?vue&type=template&id=e56a83fe&scoped=true&"
import script from "./VerificationFlowSettings.vue?vue&type=script&lang=ts&"
export * from "./VerificationFlowSettings.vue?vue&type=script&lang=ts&"
import style0 from "./VerificationFlowSettings.vue?vue&type=style&index=0&id=e56a83fe&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e56a83fe",
  null
  
)

export default component.exports