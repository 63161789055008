












































































import Vue from "vue";

import RulesList from "@/components/rules/RulesList.vue";
import CreateRules from "@/components/rules/CreateRules.vue";
import PriceCalculator from "../../PriceCalculator.vue";
import KYCRequirements from "./KYCRequirements.vue";

export default Vue.extend({
  components: {
    KYCRequirements,
    RulesList,
    CreateRules,
    PriceCalculator,
  },
  name: "KYCVerificationRequirements",
  props: {
    cappFilteredSyncVerifications: {
      required: true,
      type: Array as () => Array<any>,
    },
    companyAppPermissions: { required: true, type: Array as () => Array<any> },
    cappFilteredSyncVerificationsInput: {
      required: true,
      type: Array as () => Array<any>,
    },
    rulesList: { required: true, type: Array as () => Array<any> },
    hasPEP: { required: true, type: Boolean },
    hasAML: { required: true, type: Boolean },
    hasKYC: { required: true, type: Boolean },
    recurrentAMLOptions: { required: true, type: Array as () => Array<any> },
    recurrentAMLValue: { required: true, type: String },
    create: { required: true, type: Boolean },
  },
  data() {
    return {
      createRule: false,
    };
  },
});
