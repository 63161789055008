var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('KYBStatusInfo',{staticClass:"company-ubo-detail context",attrs:{"KYBCompanyDetail":_vm.KYBCompanyDetail,"KYBCompanyReminders":_vm.KYBCompanyReminders},on:{"getKYBCompanyDetail":function($event){return _vm.$emit('getKYBCompanyDetail')},"getCompanyReminders":function($event){return _vm.$emit('getCompanyReminders')}}}),(_vm.KYBCompanyDetail.details)?_c('KYBProgressTabs',{attrs:{"activeTabName":_vm.activeTabName,"KYBCompanyDetail":_vm.KYBCompanyDetail,"adminInformation":_vm.uboDetail || {},"KYBCompanyDetailSanctions":_vm.KYBCompanyDetailSanctions,"KYBCompanyDetailAdverseMedia":_vm.KYBCompanyDetailAdverseMedia},on:{"activeTabName":function($event){_vm.activeTabName = $event},"showAndGetRepresentativeDetail":function($event){return _vm.getRepresentative()}}}):_vm._e(),(_vm.loading)?_c('div',{staticClass:"v-spinner",class:{ loading: _vm.loading }}):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"ubo-detail-wrapper"},[(_vm.activeTabName === 'companyInfo')?_c('KYBCompanyInfo',{attrs:{"hasContact":!!_vm.KYBCompanyDetail &&
        !!_vm.KYBCompanyDetail.contact &&
        !!_vm.KYBCompanyDetail.contact.user &&
        !!_vm.KYBCompanyDetail.contact.user.email,"companyInfoDetails":_vm.KYBCompanyDetail.details,"kybCompanyId":_vm.KYBCompanyDetail.id},on:{"getKYBCompanyDetail":function($event){return _vm.$emit('getKYBCompanyDetail')}}}):_vm._e(),(_vm.activeTabName === 'companyExtraDocuments')?_c('KYBExtraDocuments',{attrs:{"extraDocuments":_vm.KYBCompanyDetail.files,"KYBCompanyDetail":_vm.KYBCompanyDetail,"loadingProp":_vm.loading},on:{"getKYBCompanyDetail":function($event){return _vm.$emit('getKYBCompanyDetail')}}}):_vm._e(),(
        _vm.uboDetail &&
        _vm.uboDetail.processing_attempts &&
        _vm.uboDetail.processing_attempts.length &&
        _vm.activeTabName === 'representativeDetail'
      )?_c('UserDetail',{staticClass:"user-detail",attrs:{"loadingProp":_vm.loading,"resource":_vm.uboDetail.processing_attempts[0],"KYBCompanyPrincipals":_vm.KYBCompanyDetailPrincipals,"showResourceDetail":_vm.showUBODetail,"user":_vm.uboDetail},on:{"onUpdateResource":function($event){return _vm.$emit('updateResource', $event)}}}):_vm._e(),(_vm.KYBCompanyDetail.details && _vm.activeTabName === 'UBODiagram')?_c('UBODiagram',{attrs:{"KYBCompanyData":_vm.KYBCompanyDetail,"countriesList":_vm.countriesList},on:{"showUBODetail":function($event){return _vm.$emit('showAndGetUBODetail', $event)}}}):_vm._e(),(_vm.activeTabName === 'sanctionsChecks')?_c('KYBSanctionsChecks',{attrs:{"sanctions":_vm.KYBCompanyDetailSanctions || {},"kybCompanyId":_vm.KYBCompanyDetail.id},on:{"getKYBCompanyDetail":function($event){return _vm.$emit('getKYBCompanyDetail', _vm.KYBCompanyDetail.id)}}}):_vm._e(),(_vm.activeTabName === 'adverseMediaChecks')?_c('KYBAdverseMediaChecks',{attrs:{"adverseMedia":_vm.KYBCompanyDetailAdverseMedia || {},"kybCompanyId":_vm.KYBCompanyDetail.id},on:{"getKYBCompanyDetail":function($event){return _vm.$emit('getKYBCompanyDetail', _vm.KYBCompanyDetail.id)}}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }