import {
  AML_AND_PEP_VERIFICATIONS_TYPES_VIEW_MAP,
  VerificationAPIType,
  VERIFICATIONS_TYPES_VIEW_MAP,
} from "@/models/verificationsTypes";

import {
  VERIFICATION_STATUS_MAP,
  VerificationStatus,
} from "@/models/verificationStatus";
import Decimal from "decimal.js";

export function getUniqueErrorsArrayFromCoreModules(
  core_modules_executions: Array<any>
): Array<string> {
  let errorsArray: Array<string> = [];

  for (const cm_exec of core_modules_executions) {
    errorsArray = [...errorsArray, ...cm_exec.errors];
  }
  let unique = [...new Set(errorsArray)].map((e) =>{
    if(e.startsWith("E_")) e = e.substring(2)
    return e.replace(/(_)/g, " ")
  }
  );
  return unique;
}

export function truncateMiddleString(str: string): string {
  return (
    str.substring(0, 5) + "..." + str.substring(str.length - 5, str.length)
  );
}

export function truncateString(str: string): string {
  return str.substring(0, 10);
}

export function filteredSyncVerifications(
  verifications: Array<Array<VerificationAPIType>>
): Array<Array<VerificationAPIType>> {
  let returnVerifications: Array<Array<VerificationAPIType>> = [];
  for (let vg of verifications) {
    const filteredVerifications = vg.filter(
      (p: any) => VERIFICATIONS_TYPES_VIEW_MAP[p]
    );
    if (filteredVerifications.length > 0)
      returnVerifications.push(filteredVerifications);
  }
  return returnVerifications;
}
export function filteredSyncVerificationsAndChecks(
  verifications: Array<Array<VerificationAPIType>>
) {
   const compareArrays = (a: VerificationAPIType[], b: VerificationAPIType[]) => {
    const aString = a.join(',');
    const bString = b.join(',');
    
    if (aString < bString) return -1;
    if (aString > bString) return 1;
    return 0;
  };
  let returnVerifications: Array<VerificationAPIType[]> = [];
  for (let vg of verifications) {
    const filteredVerifications = vg.filter((p: any) => {
      const [verificationType] = p.split(":");
      return (
        VERIFICATIONS_TYPES_VIEW_MAP[p] ||
        AML_AND_PEP_VERIFICATIONS_TYPES_VIEW_MAP[verificationType]
      );
    });
    if (filteredVerifications.length > 0) {
      const transformedVerifications: Array<VerificationAPIType> = filteredVerifications.map((verification: any) => {
        if (verification.includes(":background")) {
          let verificationParsed = verification.split(":")[0];
          if (verificationParsed === 'AML' && verification.includes("expires"))
            return verificationParsed + verification.split(":")[2];
          return verificationParsed
        }
        return verification;
      });
      transformedVerifications.sort();
      returnVerifications.push(transformedVerifications);
    }
  }
  returnVerifications.sort(compareArrays);
  return [returnVerifications];
}

export function toUpperCase(
  data: any,
  options: { include?: Array<string>; exclude?: Array<string> } = {
    include: [],
    exclude: [],
  }
): any {
  if (data && typeof data === "string") {
    return data.toUpperCase();
  }
  if (data && typeof data === "object" && !data.length) {
    const newData = Object.keys(data).reduce((accumulator, key: any) => {
      if (typeof data[key] === "string") {
        if (options.exclude && options.exclude.length > 1) {
          if (
            !data[key] ||
            options.exclude.some((innerkey) =>
              key.toLowerCase().includes(innerkey.toLowerCase())
            )
          )
            return { ...accumulator, [key]: data[key] };
        }
        if (options.include && options.include.length > 1) {
          if (
            !data[key] ||
            !options.include.some((innerkey) =>
              key.toLowerCase().includes(innerkey.toLowerCase())
            )
          )
            return { ...accumulator, [key]: data[key] };
        }
        return { ...accumulator, [key]: data[key].toUpperCase() };
      }
      if (typeof data[key] === "object") {
        return { ...accumulator, [key]: toUpperCase(data[key], options) };
      }
      return { ...accumulator, [key]: data[key] };
    }, {});
    return newData;
  }
  return data;
}

// TODO: unify code of lowercase and uppercase functions
export function toLowerCase(
  data: any,
  options: { include?: Array<string>; exclude?: Array<string> } = {
    include: [],
    exclude: [],
  }
): any {
  if (data && typeof data === "string") {
    return data.toLowerCase();
  }
  if (data && typeof data === "object" && !data.length) {
    const newData = Object.keys(data).reduce((accumulator, key: any) => {
      if (typeof data[key] === "string") {
        if (options.exclude && options.exclude.length > 1) {
          if (
            !data[key] ||
            options.exclude.some((innerkey) =>
              key.toLowerCase().includes(innerkey.toLowerCase())
            )
          )
            return { ...accumulator, [key]: data[key] };
        }
        if (options.include && options.include.length > 1) {
          if (
            !data[key] ||
            !options.include.some((innerkey) =>
              key.toLowerCase().includes(innerkey.toLowerCase())
            )
          )
            return { ...accumulator, [key]: data[key] };
        }
        return { ...accumulator, [key]: data[key].toLowerCase() };
      }
      if (typeof data[key] === "object") {
        return { ...accumulator, [key]: toLowerCase(data[key], options) };
      }
      return { ...accumulator, [key]: data[key] };
    }, {});
    return newData;
  }
  return data;
}

export function PATypeStatus(
  processingAttempts: Array<any>
): { [vtype in VerificationAPIType]: VerificationStatus } {
  const uploadedPAttemptsStatus: any = {};

  for (const pa of processingAttempts) {
    const lastStoredPAttemptStatus = uploadedPAttemptsStatus[pa.permission_type];

    if (!lastStoredPAttemptStatus || new Date(pa.updated_at) > new Date(lastStoredPAttemptStatus.updated_at)) {
      uploadedPAttemptsStatus[pa.permission_type] = pa;
    }
  }

  const result: any = {};

  for (const [permissionType, paData] of Object.entries(uploadedPAttemptsStatus)) {
    result[permissionType as VerificationAPIType] = (paData as any).status;
  }

  return result;
}

export function isUboInAnyChild(rootNode: any, id: string): boolean{
  if(rootNode.id === id || rootNode.admin && rootNode.admin && rootNode.admin.id === id) return true;
  if(!rootNode.ubo_list || !rootNode.ubo_list.length) return false;
  for(const node of rootNode.ubo_list){
    const findChild = isUboInAnyChild(node, id)
    if(findChild) return true
  }
  return false;
}

export function convertPercentageStringToDecimal(inValue:string | number): number | string {
  if (typeof inValue === "number") return new Decimal(inValue).div(100).toNumber()
  const validPercentageRegex = /^\s*-?\d+(\,\d+|\.\d+)?\s*%?\s*$/;

  if (!validPercentageRegex.test(inValue))
    return inValue

  let parsedPercentage = inValue.trim().replace(",", ".");
  if (parsedPercentage.endsWith("%")) {
    parsedPercentage = parsedPercentage.slice(0, -1);
  }
  return new Decimal(parsedPercentage).div(100).toNumber();
}