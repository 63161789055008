























































































import CDPDashboardUpload from "@/components/cdp/CDPDashboardUpload.vue";
import KYBFormModal from "@/components/kyb/KYBFormModal.vue";
import CreateRules from "@/components/rules/CreateRules.vue";
import Vue from "vue";
import DuplicateVerificationFlowModal from "../settings/components/DuplicateVerificationFlowModal.vue";

interface Action {
  copy: string;
  icon: string;
}

export default Vue.extend({
  components: {
    KYBFormModal,
    CreateRules,
    DuplicateVerificationFlowModal,
    CDPDashboardUpload,
  },
  name: "QuickActions",
  data() {
    return {
      showQuickActions: false,
      showKYBFormModal: false,
      showCreateRulesFormModal: false,
      showUploadDocument: false,
      showDuplicateVerificationFlowModal: false,
      signup_url: process.env.VUE_APP_SILT_SIGNUP_URL,
    };
  },
  computed: {
    verificationFlowId(): string {
      return this.$store.state.verificationFlow.app_id || "";
    },
    actionButton(): () => void {
      const { verificationFlow } = this.$store.state;

      switch (verificationFlow.flow_type) {
        case "KYB":
          return this.openKYBModal.bind(this);
        case "UDV":
          return this.openKYCVerificationLink.bind(this);
        case "CDP":
          return this.openUploadDocumentModal.bind(this);
        default:
          return this.openUploadDocumentModal.bind(this);
      }
    },
    firstQuickAction(): Action {
      const { verificationFlow } = this.$store.state;

      switch (verificationFlow.flow_type) {
        case "KYB":
          return {
            icon: "fa-building",
            copy: "mainnav_quick_action_verify_new_company_button",
          };
        case "UDV":
          return {
            icon: "fa-link",
            copy: "mainnav_quick_action_verify_new_user_button",
          };
        case "CDP":
          return {
            icon: "fa-upload",
            copy: "mainnav_quick_action_upload_document_button",
          };
        default:
          return {
            icon: "fa-link",
            copy: "mainnav_quick_action_verify_new_user_button",
          };
      }
    },
  },
  methods: {
    openKYBModal() {
      this.showKYBFormModal = true;
      this.showQuickActions = false;
    },
    openUploadDocumentModal() {
      this.showUploadDocument = true;
      this.showQuickActions = false;
    },
    openKYCVerificationLink() {
      const url = `${this.signup_url}/?company_app_id=${this.verificationFlowId}&hide_redirect_button=true`;
      window.open(url, "_blank");
    },
  },
});
