













































import Vue from "vue";

interface VerificationFlowStyle {
  style: {
    primary_color: string;
    title_font_url: string;
    body_font_url: string;
  };
}

export default Vue.extend({
  name: "AppDetailStyle",
  props: {
    companyApp: { required: true, type: Object },
  },
  computed: {
    shouldRenderReviewerViewWithMissingFields(): boolean {
      return (
        this.$store.state.userRole === "REVIEWER" &&
        [
          this.primaryColorComputed,
          this.titleFontUrlComputed,
          this.bodyFontUrlComputed,
        ].every(
          (value) => value === null || value === "" || value === undefined
        )
      );
    },
    primaryColorComputed: {
      get(): string {
        return (this.companyApp as VerificationFlowStyle).style.primary_color;
      },
      set(value: string) {
        this.updateVerificationFlow({
          style: {
            primary_color: value,
            title_font_url: this.titleFontUrlComputed,
            body_font_url: this.bodyFontUrlComputed,
          },
        });
      },
    },
    titleFontUrlComputed: {
      get(): string {
        return (this.companyApp as VerificationFlowStyle).style.title_font_url;
      },
      set(value: string) {
        this.updateVerificationFlow({
          style: {
            primary_color: this.primaryColorComputed,
            title_font_url: value,
            body_font_url: this.bodyFontUrlComputed,
          },
        });
      },
    },
    bodyFontUrlComputed: {
      get(): string {
        return (this.companyApp as VerificationFlowStyle).style.body_font_url;
      },
      set(value: string) {
        this.updateVerificationFlow({
          style: {
            primary_color: this.primaryColorComputed,
            title_font_url: this.titleFontUrlComputed,
            body_font_url: value,
          },
        });
      },
    },
  },
  data() {
    return {
      primaryColorHexFeedback: "",
      verificationFlowStyle: {
        style: {
          body_font_url: this.companyApp.style.body_font_url,
          title_font_url: this.companyApp.style.title_font_url,
          primary_color: this.companyApp.style.primary_color,
        },
      },
    };
  },
  watch: {
    "companyApp.style.primary_color": function (primaryColorValue: string) {
      this.validatePrimaryColor(primaryColorValue);
    },
  },

  methods: {
    validatePrimaryColor(primaryColorValue: string) {
      const regexHex = /^#?([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/;
      if (primaryColorValue.length > 3) {
        if (!regexHex.test(primaryColorValue)) {
          this.primaryColorHexFeedback = "Invalid hexadecimal format";
        } else {
          this.primaryColorHexFeedback = "";
        }
      } else {
        this.primaryColorHexFeedback = "";
      }
    },
    updateVerificationFlow(update: Partial<VerificationFlowStyle>) {
      const newVerificationFlow = {
        ...this.verificationFlowStyle,
        ...update,
      };
      this.verificationFlowStyle = newVerificationFlow;
      this.$emit("updateVerificationFlowInfo", newVerificationFlow);
    },
  },
});
