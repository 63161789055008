































import Vue from "vue";
import KYBExtraDocumentForm, { DocumentData } from "./KYBExtraDocumentForm.vue";

export default Vue.extend({
  components: { KYBExtraDocumentForm },
  name: "KYBRequirementsExtraDocuments",
  watch: {},
  props: {
    extraDocuments: {
      required: true,
      type: Array as () => Array<DocumentData>,
    },
  },
  data() {
    return {};
  },
});
