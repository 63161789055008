





























































import Vue from "vue";
import KYBFormModal from "@/components/kyb/KYBFormModal.vue";
import KYBModal from "./components/KYBModal.vue";
import KYBService from "@/api/kyb/kybService";
import {
  VTableHeader,
  VTableInstance,
} from "@/components/vuemarc-ui-kit/tables/VTable.vue";
import { COMPANY_STATUSES } from "@/models/verificationStatus";

export default Vue.extend({
  components: { KYBFormModal, KYBModal },
  name: "KYBCompaniesList",
  data() {
    return {
      showKYBModal: false,
      loading: false,
      KYBCompanyDetail: {} as any,
      headers: [
        {
          name: "status",
          text: "status_title",
          style: "max-width: 180px",
        },
        {
          name: "company_name",
          text: "companies_company_name_title",
        },
        {
          name: "contact_email",
          text: "companies_contact_email_title",
        },
        {
          name: "created_at",
          text: "created_date_title",
          class: "text-right shrinked",
        },
      ] as VTableHeader[],
      searchParamsPropString: "",
      filterOptions: COMPANY_STATUSES,
      KYBCompanyDetailSanctions: {} as any,
      KYBCompanyDetailAdverseMedia: {} as any,
      KYBCompanyReminders: [] as any,
      loadingReminders: false,
    };
  },
  computed: {
    isKYBFlow() {
      return this.$store.state.verificationFlow.flow_type === "KYB";
    },
  },
  methods: {
    getKYBCompanyPrefilled(KYBCompanyId: any) {
      this.KYBCompanyDetail =
        this.$refs.KYBTable &&
        (this.$refs.KYBTable as VTableInstance).rowsData.results.find(
          (KYBCompany: any) => {
            if (KYBCompany.id) {
              return KYBCompany.id === KYBCompanyId;
            }
          }
        );
      return {};
    },
    async getSanctionsAndAdverseMedia(KYBCompanyId: string) {
      const kybCompanyDetail = { sanctions: null, adverseMedia: null };
      if (
        this.$store.state.verificationFlow.config?.sanctions_screening_enabled
      ) {
        const KYBCompanyDetailSanctionsResponse =
          await KYBService.getKYBCompanySanctions(KYBCompanyId);
        kybCompanyDetail.sanctions =
          KYBCompanyDetailSanctionsResponse.data[0] ?? {};
      }

      if (
        this.$store.state.verificationFlow.config
          ?.adverse_media_screening_enabled
      ) {
        const KYBCompanyDetailAdverseMediaResponse =
          await KYBService.getKYBCompanyAdverseMedia(KYBCompanyId);

        kybCompanyDetail.adverseMedia =
          KYBCompanyDetailAdverseMediaResponse.data[0] ?? {};
      }

      return kybCompanyDetail;
    },
    async getCompanyReminders(KYBCompanyId: string) {
      try {
        const KYBCompanyRemindersResponse =
          await KYBService.getKYBCompanyReminders(KYBCompanyId);

        this.KYBCompanyReminders = KYBCompanyRemindersResponse.data;
      } finally {
        this.loadingReminders = false;
      }
    },
    async showAndGetKYBCompany(KYBCompanyId: string) {
      if (KYBCompanyId) {
        if (this.KYBCompanyDetail.id !== KYBCompanyId)
          this.getKYBCompanyPrefilled(KYBCompanyId);
        this.showKYBModal = true;
        this.loading = true;
        this.loadingReminders = true;
        try {
          const currentKYBCompany = await KYBService.getKYBCompany(
            KYBCompanyId
          );
          currentKYBCompany.data.sanctions = this.KYBCompanyDetail.sanctions;
          currentKYBCompany.data.adverse_media =
            this.KYBCompanyDetail.adverse_media;
          this.KYBCompanyDetail = currentKYBCompany.data;
          if (KYBCompanyId && KYBCompanyId !== this.$route.params.companyId) {
            this.$router.push({
              name: "companies-detail",
              params: { companyId: KYBCompanyId },
            });
          }
          const { sanctions, adverseMedia } =
            await this.getSanctionsAndAdverseMedia(KYBCompanyId);
          if (sanctions) this.KYBCompanyDetailSanctions = sanctions;
          if (adverseMedia) this.KYBCompanyDetailAdverseMedia = adverseMedia;
          this.getCompanyReminders(KYBCompanyId);
        } finally {
          this.loading = false;
        }
      }
    },
    async getKYBCompanies(params?: URLSearchParams) {
      if (!this.isKYBFlow) return;
      const KYBCompaniesList = await KYBService.getKYBCompanies(
        this.$store.state.companyAppId,
        params
      );

      return KYBCompaniesList;
    },
  },
  async mounted() {
    if (this.$route.params.companyId) {
      const { data } = await KYBService.getKYBCompany(
        this.$route.params.companyId
      );
      this.KYBCompanyDetail = data;
      await this.showAndGetKYBCompany(this.$route.params.companyId);
    }
  },
});
