























































import Vue from "vue";
import VDatePickerCalendar from "./components/VDatePickerCalendar.vue";

export default Vue.extend({
  name: "VDatePickerStartEnd",
  props: {
    startDate: { required: true, type: Date },
    endDate: { required: true, type: Date },
    showCalendarProp: { required: false, type: Boolean },
  },
  components: { VDatePickerCalendar },
  data() {
    return {
      showCalendar: false,
      selectDate: "",
    };
  },
  watch: {
    showCalendarProp() {
      this.showCalendar = this.showCalendarProp;
    },
  },
  methods: {
    onSelectedDateChanged(val: Date | undefined, type: string) {
      if (val instanceof Date && !isNaN(val.getTime())) {
        if (type === "startDate") {
          this.$emit("startDateChanged", val);
          this.selectDate = "end";
        } else {
          this.$emit("endDateChanged", val);
          this.selectDate = "start";
        }
      }
    },
    handleClose(event: Event) {
      const buttonEl = (this.$refs.calendarButton as Vue & { $el: HTMLElement })
        ?.$el;
      const calendarEl = this.$refs.calendarContainer as HTMLElement;

      if (
        buttonEl?.contains(event.target as Node) ||
        calendarEl?.contains(event.target as Node)
      ) {
        return;
      }

      this.showCalendar = false;
    },
  },
});
