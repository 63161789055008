var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-popover',{attrs:{"open":_vm.showIndividualOptions,"trigger":"manual","placement":"bottom","delay":0},on:{"auto-hide":function($event){_vm.showIndividualOptions = false}}},[_c('v-button',{staticClass:"small full-width",attrs:{"loading":_vm.loading || _vm.loadingReminders},nativeOn:{"click":function($event){_vm.showIndividualOptions = true}}},[_vm._v(" "+_vm._s(_vm.$t("send_reminder_email"))+" ")]),_c('div',{attrs:{"slot":"popover"},slot:"popover"},[_c('ul',{staticClass:"list-wrapper"},[_c('li',{staticClass:"list-item"},[_c('v-button',{staticClass:"small transparent full-width",attrs:{"disabled":_vm.reminderAllIndividualsDisabled || _vm.lastReminderSent.disabled},nativeOn:{"click":function($event){return _vm.sendAllIndividualsAnEmailReminder($event)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("send_reminder_email_all"))+" ")])]),_c('div',{staticClass:"list-item-last-sent-wrapper"},[_c('span',{staticClass:"hint"},[_vm._v(" "+_vm._s(_vm.$t("last_sent_email_reminder_hint"))+" ")]),_c('span',{staticClass:"hint"},[_vm._v(" "+_vm._s(_vm.lastReminderSent.lastSentDate)+" ")])])],1),_c('li',{staticClass:"list-item"},[_c('v-button',{staticClass:"small transparent full-width",attrs:{"disabled":!!_vm.KYBCompanyDetail.legal_acceptance_date ||
              _vm.lastReminderContactSentInfo.disabled},nativeOn:{"click":function($event){return _vm.sendKYBIndividualEmailReminderButton(
                _vm.KYBCompanyDetail.contact.id
              )}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("send_reminder_email_contact"))+" ")])]),_c('div',{staticClass:"list-item-last-sent-wrapper"},[_c('span',{staticClass:"hint"},[_vm._v(" "+_vm._s(_vm.$t("last_sent_email_reminder_hint"))+" ")]),_c('span',{staticClass:"hint"},[_vm._v(" "+_vm._s(_vm.lastReminderContactSentInfo.lastSentDate)+" ")])])],1),_c('li',{staticClass:"list-item"},[_c('v-button',{staticClass:"small transparent full-width",attrs:{"disabled":!!_vm.KYBCompanyDetail.legal_acceptance_date ||
              _vm.lastReminderRepresentativeSentInfo.disabled},nativeOn:{"click":function($event){return _vm.sendKYBIndividualEmailReminderButton(_vm.KYBCompanyDetail.admin.id)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("send_reminder_email_admin"))+" ")])]),_c('div',{staticClass:"list-item-last-sent-wrapper"},[_c('span',{staticClass:"hint"},[_vm._v(" "+_vm._s(_vm.$t("last_sent_email_reminder_hint"))+" ")]),_c('span',{staticClass:"hint"},[_vm._v(" "+_vm._s(_vm.lastReminderRepresentativeSentInfo.lastSentDate)+" ")])])],1),(
            (_vm.$store.state.verificationFlow.config &&
              _vm.$store.state.verificationFlow.config
                .ubo_verification_enabled) ||
            _vm.lastReminderUBOsListSentInfo.disabled
          )?_c('li',{staticClass:"list-item"},[_c('v-button',{staticClass:"small transparent full-width",attrs:{"disabled":_vm.reminderUBOsDisabled},nativeOn:{"click":function($event){return _vm.sendUBOsEmails(_vm.kybIndividualId)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("send_reminder_email_ubo"))+" ")])]),_c('div',{staticClass:"list-item-last-sent-wrapper"},[_c('span',{staticClass:"hint"},[_vm._v(" "+_vm._s(_vm.$t("last_sent_email_reminder_hint"))+" ")]),_c('span',{staticClass:"hint"},[_vm._v(" "+_vm._s(_vm.lastReminderUBOsListSentInfo.lastSentDate)+" ")])])],1):_vm._e()])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }