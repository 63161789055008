









































































































































import {
  VerificationAPIType,
  KYC_VERIFICATIONS_TYPES_VIEW_MAP,
} from "@/models/verificationsTypes";
import Vue from "vue";
// TODO: Check why on updateConfigurationFlow sometimes cappFilteredSyncVerificationsInput[i][j] is null | undefined
export default Vue.extend({
  components: {},
  name: "KYCDocumentTypes",
  props: {
    companyAppPermissions: { required: true, type: Array as () => Array<any> },
    cappFilteredSyncVerificationsInput: {
      required: true,
      type: Array as () => Array<any>,
    },
  },
  data() {
    return {
      modifyVerifications: false,
      KYC_VERIFICATIONS_TYPES_VIEW_MAP: KYC_VERIFICATIONS_TYPES_VIEW_MAP,
    };
  },
  computed: {
    verificationOptions(): Array<any> {
      const verifications = [] as any;

      const hasSingleElementGroup =
        this.cappFilteredSyncVerificationsInput.some(
          (group) => group.length === 1
        );

      outerloop: for (let key of Object.keys(
        KYC_VERIFICATIONS_TYPES_VIEW_MAP
      )) {
        if (key === "UPOA" && !hasSingleElementGroup) continue outerloop;

        for (let cappVerifGroup of this.cappFilteredSyncVerificationsInput) {
          if (
            cappVerifGroup &&
            cappVerifGroup.includes(key as VerificationAPIType)
          )
            continue outerloop;
        }
        verifications.push({
          value: key,
          text: this.$t(key.toLowerCase()),
        });
      }
      return verifications;
    },
    areAllOptionsSelected(): boolean {
      let totalOptionsSelected = 0;
      for (const verificationGroup of this.cappFilteredSyncVerificationsInput) {
        totalOptionsSelected += verificationGroup.length;
      }
      if (
        Object.keys(KYC_VERIFICATIONS_TYPES_VIEW_MAP).length ===
        totalOptionsSelected
      )
        return true;
      return false;
    },
    option(): Array<string> {
      const hasUPOA = this.cappFilteredSyncVerificationsInput.some(
        (permissionsGroup: Array<string>) => permissionsGroup.includes("UPOA")
      );
      return hasUPOA ? [this.verificationOptions[0].value] : ["UPOA"];
    },
  },
});
