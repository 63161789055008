





















































































































































































































import Vue from "vue";
import KYBIgnoreStatus from "./components/KYBIgnoreStatus.vue";
import KYBService from "@/api/kyb/kybService";
import { TranslateResult } from "vue-i18n";
import KYBIndividualEmailReminderButton, {
  LastReminder,
} from "./components/KYBIndividualEmailReminderButton.vue";
import eventBus from "@/lib/utils";
import KYBTempTokenButton from "./components/components/KYBTempTokenButton.vue";

export default Vue.extend({
  components: {
    KYBIgnoreStatus,
    KYBIndividualEmailReminderButton,
    KYBTempTokenButton,
  },
  name: "KYBStatusInfo",
  props: {
    KYBCompanyDetail: { required: true, type: Object },
    isRootKYBCompany: {
      required: false,
      default: false,
      type: Boolean,
    },
    KYBCompanyReminders: { required: true, type: Array as () => Array<any> },
    loadingReminders: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      showIgnoreModal: false,
      loading: false,
      contactEmail: "",
      showContactInput: false,
      emailErrors: "" as TranslateResult,
      showRemindersHistory: false,
      ignoreClickOutside: false,
    };
  },
  watch: {
    contactEmail(contactEmail) {
      this.emailHasErrors(contactEmail);
    },
    KYBCompanyDetail: {
      handler() {
        if (this.showRemindersHistory) this.showRemindersHistory = false;
        this.contactEmail = this.KYBCompanyDetail.contact?.user?.email || "";
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    showKYBIndividualEmailReminderButton(): boolean {
      return (
        !["SCREENING", "SUCCESS"].includes(this.KYBCompanyDetail.status) ||
        (this.$store.state.verificationFlow?.config?.ubo_verification_enabled &&
          this.KYBCompanyDetail.status !== "SCREENING" &&
          this.KYBCompanyDetail.ubo_list?.length > 0 &&
          !this.KYBCompanyDetail.ubo_list.every((ubo: { status: string }) =>
            ["SUCCESS", "MANUAL_SUCCESS"].includes(ubo.status)
          ))
      );
    },
    lastReminderSent(): LastReminder {
      const lastReminderSent = this.KYBCompanyReminders?.find(
        (reminder: any) => reminder.status === "SENT"
      ) as { updated_at: string };
      if (!lastReminderSent || this.loadingReminders)
        return { lastSentDate: "-", disabled: false };
      return {
        lastSentDate: this.$options?.filters?.dateTime(
          lastReminderSent.updated_at
        ),
        disabled: this.$refs.KYBIndividualEmailReminderButton
          ? (
              this.$refs.KYBIndividualEmailReminderButton as any
            ).isLessThan24Hours(lastReminderSent.updated_at)
          : false,
      };
    },
  },
  methods: {
    toggleRemindersHistory() {
      this.showRemindersHistory = !this.showRemindersHistory;

      if (this.showRemindersHistory) {
        this.ignoreClickOutside = true;
        setTimeout(() => {
          this.ignoreClickOutside = false;
        }, 0);
      }
    },
    handleClose() {
      if (this.ignoreClickOutside) return;
      this.showRemindersHistory = false;
    },
    getReminderIndividualRole(individualId: string) {
      if (individualId === this.KYBCompanyDetail.contact?.id)
        return this.$t("contact");
      if (individualId === this.KYBCompanyDetail.admin?.id)
        return this.$t("companies_detail_representative");
      if (
        this.KYBCompanyDetail.ubo_list?.some(
          (ubo: any) => ubo.id === individualId
        )
      )
        return this.$t("ubo");
    },
    async ignoreKYBCompany() {
      try {
        this.loading = true;
        await KYBService.ignoreKYBCompany(this.KYBCompanyDetail.id);

        this.$emit("getKYBCompanyDetail");
      } finally {
        this.loading = false;
        this.showIgnoreModal = false;
      }
    },
    downloadCertificate(url: string) {
      window.open(url, "_blank");
    },
    async setContact() {
      try {
        this.loading = true;
        if (!this.emailErrors) {
          await KYBService.setContact(
            this.KYBCompanyDetail.id,
            this.contactEmail
          );

          eventBus.$emit("v-toast", {
            localizedKey: "successful_mail_feedback",
            type: "success-toast",
          });

          this.showContactInput = false;

          this.$emit("getKYBCompanyDetail");
        }
      } finally {
        this.loading = false;
        this.showIgnoreModal = false;
      }
    },
    emailHasErrors(email: string): TranslateResult {
      if (
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) ||
        !email.length ||
        email.length < 10
      ) {
        this.emailErrors = "";
        return "";
      }
      this.emailErrors = this.$t("email_validator_error_feedback");
      return this.emailErrors;
    },
  },
});
