



















import Vue from "vue";
import AdminsTable from "@/components/admins/AdminsTable.vue";

export default Vue.extend({
  name: "AppDetailAdminsAndAccess",
  components: { AdminsTable },
  props: { companyApp: { required: true, type: Object } },
});
