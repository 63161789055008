var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-input',_vm._b({staticClass:"v-select-search",class:{
    'v-input--dirty': _vm.dirty,
    'v-input--typed': _vm.focused || !_vm.isEmpty,
    error: _vm.required && _vm.dirty && !_vm.value,
    active: _vm.showOptions,
    'v-input-appended-icon': true,
  }},'v-input',_vm.$options.propsData,false),[_c('v-popover',{attrs:{"open":_vm.showOptions,"placement":_vm.popoverPlacement,"trigger":"manual","delay":0},on:{"auto-hide":function($event){_vm.showOptions = false}}},[_c('div',{staticClass:"v-select-search__selected v-input__slot__input capitalized",on:{"click":function($event){return _vm.toggleOptions()}}},[(_vm.placeholder && !_vm.inputValue)?_c('span',{staticClass:"v-input__slot__input-placeholder"},[_vm._v(" "+_vm._s(_vm.$t(_vm.placeholder))+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("clipped")(_vm._f("clean_cap")((_vm.valueText || _vm.inputValue)),_vm.clip)))])]),_c('i',{staticClass:"appended-icon fa fa-chevron-down"}),_c('template',{slot:"popover"},[_c('div',{staticClass:"v-select-search__options-list tooltip-content",class:{ active: _vm.showOptions }},[_c('div',{staticClass:"v-select-search__options-list__container"},[(_vm.filterOptions().length === 0)?_c('p',{staticClass:"list-alert"},[_vm._v(" No companies found ")]):_vm._e(),_vm._l((_vm.filterOptions()),function(option,index){return [_c('div',{key:((option.value) + "-" + index),staticClass:"v-select-search__options-list__option",class:{ active: _vm.inputValue === option.value },on:{"click":function($event){_vm.multipleValues
                  ? _vm.addMultipleValues(option.value)
                  : (_vm.inputValue = option.value) && _vm.hideOptions()}}},[_c('div',{class:("option-" + index + " " + (option.value) + " capitalized " + (option.class))},[(_vm.multipleValues)?_c('v-checkbox',{class:_vm.values.some(function (value) { return value === option.value; })
                      ? 'active'
                      : '',attrs:{"text":option.text || option.value}}):_vm._t("optionListItem",[_vm._v(" "+_vm._s(option.text || option.value)+" ")],{"option":option})],2)])]})],2),(_vm.options.length > 3)?_c('v-text-field',{ref:"searchField",staticClass:"search-bar small",attrs:{"placeholder":_vm.$t('rules_create_new_rule_operator_value_filter')},model:{value:(_vm.filterSearchOptions),callback:function ($$v) {_vm.filterSearchOptions=$$v},expression:"filterSearchOptions"}}):_vm._e()],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }