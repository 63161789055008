

























































































































import KYBService from "@/api/kyb/kybService";
import eventBus from "@/lib/utils";
import Vue from "vue";

export interface LastReminder {
  lastSentDate: string;
  disabled: boolean;
}

export default Vue.extend({
  props: {
    KYBCompanyDetail: { required: true, type: Object },
    KYBCompanyReminders: { required: true, type: Array as () => Array<any> },
    lastReminderSent: { required: true, type: Object as () => LastReminder },
    loadingReminders: { required: true, type: Boolean },
  },
  name: "KYBIndividualEmailReminderButton",
  data() {
    return {
      showIndividualOptions: false,
      loading: false,
      kybIndividualId: "",
    };
  },
  watch: {
    showIndividualOptions() {
      if (this.showIndividualOptions)
        this.$root.$emit("analytics", "SendReminderEmail Clicked");
    },
  },
  computed: {
    reminderUBOsDisabled(): boolean {
      return (
        (this.KYBCompanyDetail.ubo_list?.length > 0 &&
          this.KYBCompanyDetail.ubo_list.every((ubo: { status: string }) =>
            ["SUCCESS", "MANUAL_SUCCESS"].includes(ubo.status)
          )) ||
        !this.KYBCompanyDetail.legal_acceptance_date
      );
    },
    reminderAllIndividualsDisabled(): boolean {
      return (
        this.reminderUBOsDisabled &&
        ["SUCCESS", "MANUAL_SUCCESS"].includes(
          this.KYBCompanyDetail.admin?.status
        ) &&
        !!this.KYBCompanyDetail.admin_email
      );
    },
    lastReminderUBOsListSentInfo(): LastReminder {
      if (this.KYBCompanyDetail?.ubo_list?.length > 0) {
        for (const ubo of this.KYBCompanyDetail.ubo_list) {
          if (!["SUCCESS", "MANUAL_SUCCESS"].includes(ubo.status))
            return this.getLastReminderSentInfo(ubo.id);
        }
      }

      return { lastSentDate: "-", disabled: false };
    },
    lastReminderRepresentativeSentInfo(): LastReminder {
      if (!this.KYBCompanyDetail.admin_email)
        return { lastSentDate: "-", disabled: false };
      return this.getLastReminderSentInfo(this.KYBCompanyDetail.admin?.id);
    },
    lastReminderContactSentInfo(): LastReminder {
      return this.getLastReminderSentInfo(this.KYBCompanyDetail.contact?.id);
    },
  },
  methods: {
    getCurrentDate(): string {
      const currentDate = new Date();
      const isoString = currentDate.toISOString();

      const formattedDate = isoString.replace("Z", "+00:00");

      return formattedDate;
    },
    getLastReminderSentInfo(kybIndividualId: string): LastReminder {
      const lastSentInfo = {
        lastSentDate: "-",
        disabled: false,
      };

      if (this.KYBCompanyReminders?.length < 0) return lastSentInfo;

      const kybIndividual: any = this.KYBCompanyReminders.find(
        (reminder: any) =>
          reminder.kyb_individual_id === kybIndividualId &&
          reminder.status === "SENT"
      );

      if (kybIndividual) {
        lastSentInfo.lastSentDate = this.$options?.filters?.dateTime(
          kybIndividual.updated_at
        );

        const updatedAtDate = new Date(kybIndividual.updated_at);

        if (this.isLessThan24Hours(updatedAtDate)) {
          lastSentInfo.disabled = true;
        }
      }
      return lastSentInfo;
    },
    isLessThan24Hours(updatedAt: Date): boolean {
      const currentDate = new Date();
      const updatedAtDate = new Date(updatedAt);
      if (!isNaN(currentDate.getTime()) && !isNaN(updatedAtDate.getTime())) {
        const diffInHours =
          (currentDate.getTime() - updatedAtDate.getTime()) / (1000 * 60 * 60);
        return diffInHours < 24;
      }
      return false;
    },
    async sendAllIndividualsAnEmailReminder() {
      this.kybIndividualId = await this.processAdminOrContact(
        this.KYBCompanyDetail
      );

      await this.sendUBOsEmails(this.kybIndividualId);
    },
    async sendUBOsEmails(kybIndividualId: string) {
      const uboList = this.KYBCompanyDetail.ubo_list;

      if (
        uboList.length &&
        this.$store.state.verificationFlow?.config?.ubo_verification_enabled
      ) {
        const pendingUBOList = uboList.filter(
          ({ status, id }: { status: string; id: string }) =>
            status !== "SUCCESS" && id !== kybIndividualId
        );

        for (const ubo of pendingUBOList) {
          if (ubo.contact?.id) await this.processAdminOrContact(ubo);
          else if (ubo.user?.email)
            await this.sendKYBIndividualEmailReminderButton(ubo.id);
        }
      }
    },
    async processAdminOrContact(kybCompany: any) {
      const { contact, admin, admin_email } = kybCompany;

      const kybIndividualId = admin_email ? admin?.id : contact?.id;
      if (kybIndividualId && admin?.status !== "SUCCESS") {
        await this.sendKYBIndividualEmailReminderButton(kybIndividualId);
      }
      return kybIndividualId;
    },
    async sendKYBIndividualEmailReminderButton(kybIndividualId: string) {
      try {
        this.loading = true;
        await KYBService.sendKYBIndividualEmailReminderButton(kybIndividualId);
        eventBus.$emit("v-toast", {
          localizedKey: "email_success",
          type: "success-toast",
        });
        this.$emit("getCompanyReminders");
      } finally {
        this.loading = false;
      }
    },
  },
});
